import SVGIcon from "../ui/SVGIcon"
import Banner from "../ui/Banner.client"
import Spinner from "../ui/Spinner"

import { useState } from "react"
import { App } from "~/types"
import { Link } from "~/components/ui/Link"
import CollapsibleItem from "../CollapsibleItem"
import { CMS } from "../../sections/CMS"
import PaymentsSVGIcon from "../ui/PaymentsSVGIcon"

export type FooterProps = {
	className?: string
	footerLogo: null | CMS.ImageFileField
	menu: null | App.StorefrontAPI.Menu
	shop: App.StorefrontAPI.Shop
}

const Footer = (props: FooterProps) => {
	const [emailInput, setEmailInput] = useState<string>("")
	const [isLoading, setIsloading] = useState<boolean>(false)
	const [resMessage, setResMessage] = useState<string>("")
	const [showBanner, setShowBanner] = useState<boolean>(false)

	const socailMediaLinks = [
		{
			icon: "instagram",
			url: "https://www.instagram.com/brando.no/"
		},
		{
			icon: "facebook",
			url: "https://www.facebook.com/brandooffisiell"
		}
	]

	const renderSocialMediaIcons = () => {
		const iconSize = 24
		return (
			<div className="flex items-center justify-center space-x-4">
				{socailMediaLinks.map((social, i) => {
					return (
						<a
							key={i}
							href={social.url}>
							<SVGIcon
								size={iconSize}
								// @ts-expect-error
								name={social.icon}
							/>
						</a>
					)
				})}
			</div>
		)
	}

	return (
		<footer
			className={`${
				props.className != null ? props.className : ""
			} container mx-auto pb-8`}>
			<div
				style={{
					boxShadow: "4px 5px 10px #0000002e",
					borderRadius: "18px",
					marginTop: "2rem",
					marginBottom: "2rem"
				}}>
				<div className="flex flex-col-reverse w-full md:flex-row justify-between p-4 md:p-12 items-center text-center md:text-left">
					<div className="md:hidden mt-4">{renderSocialMediaIcons()}</div>
					<div className="hidden md:flex justify-between w-1/2">
						{props.menu?.items.map((menu, i) => {
							return (
								<div
									key={i}
									className="space-y-2">
									<div
										style={{
											fontFamily: "var(--heading-font-family)"
										}}>
										{menu.title}
									</div>
									<div className="space-y-2">
										{menu.items != null &&
											menu.items.map((linkitem, i) => {
												return (
													<div key={i}>
														<Link
															style={{
																fontSize: 14
															}}
															to={linkitem.url || "#"}>
															{linkitem.title}
														</Link>
													</div>
												)
											})}
									</div>
								</div>
							)
						})}
					</div>
					<div className="md:hidden w-full">
						{props.menu?.items.map((menu, i) => {
							return (
								<CollapsibleItem
									key={i}
									id={""}
									svgIconProps={{
										name: "plus",
										strokeWidth: 4,
										size: 14
									}}
									title={
										<div
											style={{
												fontFamily: "var(--heading-font-family)"
											}}>
											{menu.title}
										</div>
									}
									titleWrapperStyle={{
										padding: "18px 0px",
										borderBottom: "1px solid #e8e8e8"
									}}
									renderBody={() => (
										<div>
											{menu.items != null &&
												menu.items.map((linkitem, i) => {
													return (
														<Link
															to={linkitem.url || "#"}
															key={i}
															className="flex items-center justify-between"
															style={{
																fontSize: 14,
																padding: 16,
																borderBottom: "1px solid #e8e8e8"
															}}>
															<div>{linkitem.title}</div>
															<SVGIcon
																name="chevron-right"
																size={12}
															/>
														</Link>
													)
												})}
										</div>
									)}
								/>
							)
						})}
					</div>
					<div className="text-center md:text-left space-y-4 max-md:my-4">
						<h3
							style={{
								fontSize: "30px",
								lineHeight: "30px",
								fontWeight: "bold"
							}}>
							MELD MEG PÅ NYHETSBREV
						</h3>
						<div
							className="text-center"
							style={{ fontSize: "13px" }}>
							Motta nyhetsbrev med nyheter og tilbud!
						</div>
						<form
							// eslint-disable-next-line @typescript-eslint/no-misused-promises
							onSubmit={async (e) => {
								setIsloading(true)
								e.preventDefault()
								const res = await fetch("/api/customers/newsletter-form", {
									method: "POST",
									body: JSON.stringify({
										email: emailInput
									})
								})
								const data: {
									message: string
									type: string
									param: string
									successMessage: string
								} = await res.json()

								setIsloading(false)
								setResMessage(data.message || data.successMessage)
								setShowBanner(true)

								setTimeout(() => {
									setShowBanner(false)
								}, 5000)
							}}>
							<div className="flex flex-col md:flex-row md:justify-center md:space-x-2 max-md:space-y-2">
								<input
									type="email"
									placeholder="E-post..."
									style={{
										padding: "8px 15px",
										backgroundColor: "#EFEFEF",
										borderRadius: "8px"
									}}
									required={true}
									onChange={(e) => {
										setEmailInput(e.target.value)
									}}
								/>
								<button
									style={{ backgroundColor: "#3C3C3C", color: "#fff" }}
									type="submit">
									{isLoading ? <Spinner /> : "BLI MED"}
								</button>
							</div>
							{showBanner === true && (
								<div className="text-center my-2">
									<Banner
										body={resMessage}
										type="success"
										close={() => {
											setShowBanner(false)
										}}
									/>
								</div>
							)}
						</form>
						<div className="hidden md:block">{renderSocialMediaIcons()}</div>
					</div>
				</div>
			</div>

			<div className="space-y-2">
				{props.footerLogo == null ? (
					<h1
						className="text-center"
						style={{ fontWeight: "bold", fontSize: "40px" }}>
						{props.shop.name}
					</h1>
				) : (
					<img
						style={{
							margin: "0 auto",
							maxWidth: 160
						}}
						src={props.footerLogo.reference.image.url}
					/>
				)}
				{props.shop.paymentSettings.acceptedCardBrands.length > 0 && (
					<div className="w-full flex flex-wrap justify-center space-x-2">
						{props.shop.paymentSettings.acceptedCardBrands
							.reverse()
							.map((card, i) => {
								return (
									<span
										className="block"
										key={i}>
										<PaymentsSVGIcon
											name={card.toLowerCase()}></PaymentsSVGIcon>
									</span>
								)
							})}
						<span className="block">
							<PaymentsSVGIcon name="klarna" />
						</span>
						<span className="block">
							<PaymentsSVGIcon name="vipps" />
						</span>
					</div>
				)}
				<div
					style={{ fontSize: "12px" }}
					className="text-center">
					Tlf: 73 50 60 60
					<br />
					<a href="mailto:support@brando.no">support@brando.no</a>
				</div>
				<div
					style={{ fontSize: "12px", marginTop: 20 }}
					className="text-center">
					Nordre gate 6, 7010 Trondheim
					<br />
					Org nr.: 971 589 795
					<br />©{props.shop.name}
				</div>
			</div>
		</footer>
	)
}

export default Footer
