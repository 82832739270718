import { boostAPI, ParsedBoostProduct } from "./SearchController"

import { modifyShopifyImageSrc } from "../../shared/utils/modifyShopifyImageSrc"
import { formatMoney } from "~/shared/utils"
import SegmentedMenu from "./boost/SegmentedMenu"
import { Fragment, useCallback, useEffect, useRef, useState } from "react"
import { useGlobalStore } from "~/shared/useGlobalStore"
import SVGIcon from "~/components/ui/SVGIcon"
import { isMobileWidth } from "~/shared/utils"
import { locales } from "../../shared/locales"
import { useNavigate } from "@remix-run/react"
import { Link } from "../../components"

type State = {
	payload: null | {
		products: Array<ParsedBoostProduct>
		categories: Array<{
			title: string
			id: string
			u: string
			l: string
		}>
		query: string
		popularSearches: Array<{ label: string }>
	}
}

const InlineSearchBox = () => {
	const [state, setState] = useState<State>({
		payload: null
	})
	const navigator = useNavigate()

	const { query, openSearchContainer, segmentedMenus, searchInProgress } =
		useGlobalStore((state) => {
			return {
				openSearchContainer: state.search.openInlineSearchContainer,
				query: state.search.query,
				segmentedMenus: state.search.searchSegmentedMenus,
				activeSearchSegmentFilterTag: state.search.activeSearchSegmentFilterTag,
				searchInProgress: state.search.searchInProgress
			}
		})

	const isMobile = isMobileWidth()

	const inputRef = useRef<HTMLInputElement>(null)
	const searchBoxContainer = useRef<HTMLDivElement>(null)
	const inputRefContainer = useRef<HTMLDivElement>(null)

	const queryRef = useRef("")
	const onChangeInput = useCallback(
		(e: { query?: string; activeSearchSegmentFilterTag?: string }) => {
			const newParams: {
				query: string
				activeSearchSegmentFilterTag: null | string
			} = {
				query: useGlobalStore.getState().search.query,
				activeSearchSegmentFilterTag:
					useGlobalStore.getState().search.activeSearchSegmentFilterTag,
				...e
			}

			useGlobalStore.getState().setSearch({
				query: newParams.query,
				searchInProgress: newParams.query != "" ? true : false,
				activeSearchSegmentFilterTag: newParams.activeSearchSegmentFilterTag
			})

			if (window.location.pathname.startsWith("/boost/search") === true) {
				return
			}

			queryRef.current = newParams.query
			void boostAPI
				.instantSearch({
					myshopifyDomain: useGlobalStore.getState().myshopifyDomain,
					q: newParams.query,
					segmentFilterTag: newParams.activeSearchSegmentFilterTag
				})
				.then((res) => {
					if (res.query !== queryRef.current) {
						return
					}
					useGlobalStore.getState().setSearch({
						query: res.query
					})

					setState((prevState) => {
						prevState.payload = {
							products: res.products.map((product) => {
								return boostAPI.convertToProductObject(product)
							}),
							categories: res.collections.map((collection) => {
								return {
									id: collection.id.toString(),
									title: collection.title,
									l: collection.title,
									u: ("brando.no" + "/collections/" + collection.handle).replace(
										"//collections",
										"/collections"
									)
								}
							}),
							query: res.query,
							popularSearches: res.suggestions.map((suggestion) => {
								return {
									label: suggestion
								}
							})
						}
						return {
							...prevState
						}
					})

					boostAPI.updateUrlAndHistoryState({
						tag: newParams.activeSearchSegmentFilterTag,
						q: newParams.query,
						filterBy: [],
						sortBy: null,
						limit: 0
					})

					useGlobalStore.getState().setSearch({
						searchInProgress: false
					})
				})
		},
		[]
	)

	useEffect(() => {
		const header = document.querySelector("header") as HTMLElement
		if (openSearchContainer === true) {
			header.style["backdropFilter"] = "unset"
			setTimeout(() => {
				searchBoxContainer.current!.style.opacity = "1"
			}, 10)
		} else {
			header.style["backdropFilter"] = "saturate(180%) blur(2rem)"
		}
	}, [openSearchContainer, state.payload])

	const statePayload = state.payload

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault()
				navigator(`/search?q=${query}`)
				useGlobalStore.getState().setSearch({
					openInlineSearchContainer: false
				})
			}}
			style={{
				zIndex: 10000,
				position: "relative"
			}}>
			<div
				ref={inputRefContainer}
				style={{
					top: 0,
					left: 0,
					zIndex: 100000 + 1,
					width: "100%",
					padding: openSearchContainer === true && isMobile ? 20 : 0,
					position: openSearchContainer === true && isMobile ? "fixed" : "relative"
				}}>
				<input
					ref={inputRef}
					key="search-box-input"
					onChange={(e) => {
						onChangeInput({
							query: e.currentTarget.value
						})
					}}
					onFocus={() => {
						if (window.location.pathname.startsWith("/search") === false) {
							useGlobalStore.getState().setSearch({
								openInlineSearchContainer: true
							})
						}
					}}
					autoFocus={false}
					autoComplete="off"
					value={query || ""}
					name="q"
					className="!py-[6px] focus:outline-none"
					style={{
						border: "2px solid black",
						backgroundColor: "transparent",
						borderRadius: 8
					}}
					type="text"
					placeholder={locales.general.search.placeholder}
				/>
				<div
					className="flex items-center justify-center space-x-2"
					style={{
						top: "50%",
						zIndex: 5,
						position: "absolute",
						right: openSearchContainer === true && isMobile ? 28 : 5,
						transform: "translate(0, -50%)"
					}}>
					{searchInProgress === true ? (
						<div className="loader" />
					) : (
						<button
							type="submit"
							style={{
								padding: 0,
								border: "none",
								width: "fit-content",
								backgroundColor: "transparent"
							}}>
							<SVGIcon
								name="search"
								size={24}
								color="black"
							/>
						</button>
					)}
					{openSearchContainer === true && (
						<div
							className="xl:hidden"
							style={{
								width: 30,
								height: 30,
								position: "relative",
								borderRadius: "100%",
								backgroundColor: "white",
								border: "1px solid #f1f1f1",
								boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.05)"
							}}
							onClick={() => {
								useGlobalStore.getState().setSearch({
									openInlineSearchContainer: false
								})
							}}>
							<div className="transform-centered">
								<SVGIcon
									name="x"
									size={24}
									color="var(--color-cta)"
								/>
							</div>
						</div>
					)}
				</div>
			</div>

			{openSearchContainer === true && (
				<Fragment>
					<div
						onClick={() => {
							setState((prevState) => {
								prevState.payload = null
								return {
									...prevState
								}
							})
							useGlobalStore.getState().setSearch({
								openInlineSearchContainer: false
							})
						}}
						style={{
							top: 0,
							left: 0,
							zIndex: 1,
							width: "100%",
							cursor: "auto",
							height: "100vh",
							position: "fixed"
						}}
					/>

					<div
						ref={searchBoxContainer}
						className="inline-search-container overflow-y-auto"
						style={{
							right: 0,
							opacity: 1,
							zIndex: 10000,
							// - 200px is needed on non-mobile to ensure it fits within the available viewport width
							maxWidth: isMobile === true ? "100vw" : "calc(100vw - 200px)",
							backgroundColor: "#fff",
							top: isMobile ? 0 : "100%",
							borderRadius: isMobile ? 0 : 24,
							width: isMobile ? "100vw" : 1200,
							height: isMobile ? "100%" : "unset",
							position: isMobile ? "fixed" : "absolute",
							padding: isMobile ? "80px 20px 20px 20px" : 20,
							boxShadow: "4px 5px 10px hsl(0deg 0% 0% / 18%)",
							transition: "opacity 0.1s ease-in-out"
						}}>
						<SegmentedMenu
							className="!justify-start md:!justify-end"
							style={{
								marginTop: -10,
								borderBottom: "1px solid #efefef",
								marginBottom: 15
							}}
							segmentedMenus={segmentedMenus}
							onClickSegmentedMenu={(menu) => {
								onChangeInput({
									activeSearchSegmentFilterTag: menu.title
								})
							}}
						/>

						{statePayload == null ? (
							<div className="md:text-right">
								{locales.general.search.inline_search_box_title}
								{"title"}
							</div>
						) : // <div className="md:text-right">{window.__locales__.general.search.inline_search_box_title}</div>
						statePayload.categories.length <= 0 &&
						  statePayload.products.length <= 0 &&
						  statePayload.popularSearches.length <= 0 ? (
							<h5 className="text-center">
								{locales.general.search.no_results_html} "{query}"
							</h5>
						) : (
							<div className="space-y-4 relative">
								<div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-16">
									{statePayload.popularSearches.length > 0 && (
										<div className="space-y-2">
											<h6>{locales.general.search.popular_searches}</h6>
											{statePayload.popularSearches.map((search, i) => {
												return (
													<a
														href={`/search?q=${search.label}`}
														key={i}
														style={{
															padding: 0,
															fontSize: 14,
															border: "none",
															color: "black",
															fontWeight: 500,
															backgroundColor: "transparent"
														}}
														className="flex items-center space-x-2">
														<SVGIcon
															name="search"
															size={18}
															color="black"
														/>
														<div>{search.label}</div>
													</a>
												)
											})}
										</div>
									)}

									{statePayload.categories.length > 0 && (
										<div className="space-y-2">
											<h6>{locales.general.search.categories}</h6>

											<div className="flex space-x-2 overflow-x-auto">
												{statePayload.categories.map((category) => {
													return (
														<div
															key={category.id}
															style={{
																fontSize: 14,
																flexShrink: 0,
																borderRadius: 10,
																padding: "3px 6px",
																border: "2px solid black"
															}}>
															<a href={category.u}>{category.l}</a>
														</div>
													)
												})}
											</div>
										</div>
									)}
								</div>

								{statePayload.products.length > 0 && (
									<div className="space-y-2">
										<h6>
											<span>{locales.general.search.products}</span>
											<span>
												<button
													className="underline"
													type="submit"
													style={{
														padding: 1,
														color: "black",
														border: "none",
														width: "fit-content",
														backgroundColor: "transparent",
														margin:
															isMobile === false ? "0 4px" : "0 4px 0 0px"
													}}>
													{locales.general.search.view_results_for}
												</button>
											</span>
											<span>{statePayload.query}</span>
										</h6>
										<div className="flex space-x-4 items-start xl:justify-center overflow-x-auto w-full pb-4">
											{statePayload.products.map((product) => {
												return (
													<Link
														data-test="product-card"
														key={product.id}
														onClick={() => {
															useGlobalStore.getState().setSearch({
																openInlineSearchContainer: false
															})
														}}
														style={{
															width: "calc(40%)",
															boxShadow: "1px 1px 15px rgba(0,0,0,0.075)",
															borderRadius: 10,
															border: "1px solid #efefef",
															overflow: "hidden",
															flexShrink: 0,
															maxWidth: 230
														}}
														className="block text-[10px] md:text-[14px] leading-[10px] md:leading-[14px] lg:ml-[55px]"
														to={`/products/${product.handle}`}>
														{product.featured_image != null && (
															<div
																style={{
																	width: "100%",
																	position: "relative",
																	paddingBottom: `${
																		(1 / (4 / 5)) * 100
																	}%`
																}}>
																<img
																	style={{
																		top: 0,
																		left: 0,
																		zIndex: 2,
																		width: "100%",
																		height: "100%",
																		objectFit: "cover",
																		position: "absolute",
																		backgroundColor: "#f1f1f1"
																	}}
																	key={product.id}
																	src={modifyShopifyImageSrc(
																		product.featured_image,
																		{ width: 400 }
																	)}
																/>
																<div className="transform-centered">
																	<div className="loader" />
																</div>
															</div>
														)}
														<div style={{ padding: 5 }}>
															<div>{product.title}</div>
															<div
																style={{
																	color: "#999"
																}}>
																{formatMoney({
																	price: product.priceRange
																		.maxVariantPrice
																})}
															</div>
														</div>
													</Link>
												)
											})}
										</div>
										<button
											data-test="inline-search-view-results"
											type="submit"
											className="text-base"
											style={{
												padding: 5,
												width: "100%",
												color: "white",
												margin: "4px 0",
												fontWeight: 700,
												letterSpacing: "1px",
												backgroundColor: "#3c3c3c",
												textTransform: "uppercase"
											}}>
											{locales.general.search.view_results_for} {query}
										</button>
									</div>
								)}
							</div>
						)}
					</div>
				</Fragment>
			)}
		</form>
	)
}

export default InlineSearchBox
