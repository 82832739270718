import { useEffect, useState } from "react"
import { useGlobalStore } from "../../shared/useGlobalStore"
import LocationService from "../location-picker/LocationService"
import { InventoryStatus } from "./InventoryStatus"

function CartInventoryStatus() {
	const selectedLocation = useGlobalStore((state) => state.selectedLocation)
	const cart = useGlobalStore((state) => state.cart)
	const [status, setStatus] = useState<null | {
		shippable: boolean
		canBePickedUpAtSelectedLocation: boolean
	}>(null)

	useEffect(() => {
		if (cart != null) {
			void LocationService.getCartInventoryStatus({ cart }).then((status) => {
				setStatus(status)
			})
		}
	}, [cart, selectedLocation?.id])

	if (status == null) {
		return null
	}

	return (
		<InventoryStatus
			style={{
				backgroundColor: "#efefef40"
			}}
			inProgress={status == null}
			shippable={status.shippable}
			pickUp={status.canBePickedUpAtSelectedLocation}
		/>
	)
}

export default CartInventoryStatus
