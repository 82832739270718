import { useCallback, useEffect, useState } from "react"
import _ from "../../shared/utils/lodash"
import BottomSheetModal from "../../components/BottomSheetModal"
import { Checkbox } from "../../modules/search/boost/CollapsibleFilterItem"
import { locales } from "../../shared/locales"
import { useGlobalStore } from "../../shared/useGlobalStore"
import { App } from "../../types"
import SVGIcon from "../ui/SVGIcon"
import LocationService from "./LocationService"

type LocationCartStatus = {
	available: number
	unavailable: number
	status: "all-available" | "partially-available" | "unavailable"
}

export const LocationCartStatusIndicator = (props: {
	status: null | LocationCartStatus
	showStatusMessage: boolean
}) => {
	const status = props.status
	let statusColor = "var(--color-green)"
	let statusMsg = "All cart items in stock"

	// prettier-ignore
	if (status?.status === "partially-available") {
		statusColor = "orange"
		statusMsg = `${status.unavailable} cart items not in stock`
	} // prettier-ignore
	else if (status?.status === "unavailable") {
		statusColor = "var(--color-red)"
		statusMsg = `No cart items in stock`
	}

	return (
		<div>
			{status == null ? (
				<div className="skeleton-box" />
			) : (
				<div className="flex space-x-1 items-center text-xs">
					<div
						style={{
							height: 10,
							width: 10,
							borderRadius: "100%",
							backgroundColor: statusColor
						}}
					/>
					{props.showStatusMessage === true && <div>{statusMsg}</div>}
				</div>
			)}
		</div>
	)
}

const LocationCard = (props: {
	hideIfOutOfStock: boolean
	location: App.ShopifyLocation
	onSelectLocation: (location: App.ShopifyLocation) => void
}) => {
	const [locationCartStatus, setLocationCartStatus] =
		useState<null | LocationCartStatus>(null)

	const { location } = props

	const { isSelected, showLocationPickerModal, cart } = useGlobalStore((state) => {
		return {
			cart: state.cart,
			isSelected: state.selectedLocation?.id === props.location.id,
			showLocationPickerModal: state.locationPickerModal
		}
	})

	useEffect(() => {
		void LocationService.getLocationCartStatus({
			cart,
			location
		}).then((status) => setLocationCartStatus(status))
	}, [cart, location])

	if (props.hideIfOutOfStock === true && locationCartStatus?.status === "unavailable") {
		return null
	}

	return (
		<div
			data-test="location-picker-store-option"
			style={{
				position: "relative",
				border: "1px solid #efefef",
				borderRadius: "var(--button-border-radius)",
				borderColor: isSelected === true ? "var(--color-green)" : undefined
			}}
			className="text-sm cursor-pointer p-3 hover:opacity-80"
			onClick={() => {
				props.onSelectLocation(location)
			}}>
			<div
				className="flex items-center justify-center"
				style={{
					top: 4,
					right: 4,
					width: 16,
					height: 16,
					position: "absolute",
					borderRadius: "100%",
					border: "1px solid #efefef",
					borderColor: isSelected === true ? "var(--color-green)" : undefined
				}}>
				{isSelected === true && (
					<SVGIcon
						size={14}
						strokeWidth={2}
						color="var(--color-green)"
						name="check"
					/>
				)}
			</div>
			<div
				data-test="store-option-name"
				className="font-bold">
				{location.name}
			</div>
			<div>
				{location.address.city}, {location.address.address1}
			</div>

			{showLocationPickerModal.withCartInfo === true && (
				<div style={{ marginTop: 5 }}>
					<LocationCartStatusIndicator
						showStatusMessage={true}
						status={locationCartStatus}
					/>
				</div>
			)}
		</div>
	)
}

const formatLocationAddress = (location: App.ShopifyLocation) => {
	if (location.address.formatted != null) {
		return location.address.formatted.join(", ")
	}

	const parts: Array<string> = []
	if (location.address.address1 != null) {
		parts.push(location.address.address1)
	}

	if (location.address.address2 != null) {
		parts.push(location.address.address2)
	}

	if (location.address.city != null) {
		parts.push(location.address.city)
	}

	return parts.join(", ")
}

//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
type Coords = {
	latitude: number
	longitude: number
}

// Converts numeric degrees to radians
function toRad(value: number) {
	return (value * Math.PI) / 180
}

function calcCrow(fromCoords: Coords, toCoords: Coords) {
	const R = 6371 // km
	const dLat = toRad(toCoords.latitude - fromCoords.latitude)
	const dLon = toRad(toCoords.longitude - fromCoords.longitude)
	const lat1 = toRad(fromCoords.latitude)
	const lat2 = toRad(toCoords.latitude)

	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
	const d = R * c
	return d
}

const LocationPickerModal = (props: {
	onSelectLocation: (location: App.ShopifyLocation) => void
}) => {
	const [sortByDistance, setSortByDistance] = useState<null | {
		longitude: number
		latitude: number
	}>(null)

	const { _locations, selectedLocation, showLocationPickerModal, cart } = useGlobalStore(
		(state) => {
			return {
				cart: state.cart,
				_locations: state.locations,
				selectedLocation: state.selectedLocation,
				showLocationPickerModal: state.locationPickerModal
			}
		}
	)

	const [hideOutOfStock, setHideOutOfStock] = useState<boolean>(false)
	useEffect(() => {
		// Guard
		if (cart == null) {
			return
		}
		setHideOutOfStock(cart.totalQuantity > 0 ? true : false)
	}, [cart])

	let locations = _locations
	if (sortByDistance != null) {
		locations = _.sortBy(
			locations,
			(location: { address: { latitude: any; longitude: any } }) => {
				const { latitude, longitude } = location.address
				if (latitude != null && longitude != null) {
					const dist = calcCrow(sortByDistance, { latitude, longitude })
					return dist
				}
				return 100000000000000000000000000000
			}
		)
	}

	const [filterQuery, setFilterQuery] = useState("")

	const onRequestHideCb = useCallback(() => {
		useGlobalStore.setState({
			locationPickerModal: {
				show: false
			}
		})
	}, [])

	return (
		<BottomSheetModal
			id="LocationPicker"
			zIndex={100000}
			renderWithCreatePortal={false}
			onRequestHideCb={onRequestHideCb}
			show={showLocationPickerModal.show}
			// make it a bit bigger
			// className="max-w-[800px] max-h-[700px] md:max-h-[800px] "
			style={{
				maxWidth: 800,
				maxHeight: 800
			}}
			body={
				<div
					style={{
						backgroundColor: "#fff"
					}}
					className="p-3 md:p-5 space-y-4">
					{selectedLocation != null && (
						<div className="space-y-2">
							<h5>{locales.custom.my_store}</h5>
							<hr />
							<div className="flex space-x-2">
								<div>
									<div
										data-test="location-picker-selected-store-title"
										className="font-bold">
										{selectedLocation.name}
									</div>
									<div className="max-[500px]:text-xs">
										<div>{formatLocationAddress(selectedLocation)}</div>
										{selectedLocation.address.phone != null && (
											<div>{selectedLocation.address.phone}</div>
										)}
									</div>
								</div>
							</div>
							<hr />
							{/* {selectedLocation.placesAPI != null && (
								<div className="flex max-md:flex-col max-md:space-y-2 md:space-x-2">
									<div className="w-full">
										<div className="font-bold">Hours</div>
										{selectedLocation.placesAPI.opening_hours.weekday_text.map((text) => { return <div key={text}>{text}</div> })}
									</div>
									<div className="w-full md:w-[60%] flex-shrink-0">
										<iframe
											height="250" frameBorder="0" style={{ border: 0, width: '100%', backgroundColor: '#efefef' }} allowFullScreen={true} referrerpolicy="no-referrer-when-downgrade"
											src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBp6lCufsoyvIrSsSKOMhssv79DEDuK7_Y&q=place_id:${selectedLocation.placesAPI.place_id}`}>
										</iframe>
									</div>
								</div>
							)} */}
						</div>
					)}

					<h5>{locales.custom.change_store}</h5>

					<hr />
					<div className="flex space-x-2">
						<div
							style={{ width: "100%" }}
							className="relative">
							<SVGIcon
								name="search"
								size={22}
								style={{
									position: "absolute",
									top: "50%",
									left: 5,
									zIndex: 5,
									transform: "translateY(-50%)"
								}}
							/>
							<input
								className="Search for store..."
								type="text"
								placeholder={locales.general.search.placeholder}
								style={{
									padding: 8,
									paddingLeft: 28,
									width: "100%",
									borderRadius: "var(--button-border-radius)",
									border: "1px solid #000"
								}}
								onChange={(e) => {
									setFilterQuery(e.currentTarget.value)
								}}
							/>
						</div>
						<div
							onClick={() => {
								setHideOutOfStock(!hideOutOfStock)
							}}
							style={{
								width: 140,
								flexShrink: 0,
								border: "1px solid #000",
								borderColor:
									hideOutOfStock === true ? "var(--color-green)" : undefined,
								borderRadius: "var(--button-border-radius)"
							}}
							className="flex space-x-1 justify-center items-center cursor-pointer">
							<Checkbox
								size={20}
								checkedColor="var(--color-green)"
								uncheckedColor="#e5e7eb"
								checked={hideOutOfStock === true}
							/>
							<div
								style={{ letterSpacing: 0 }}
								className="text-xs">
								{locales.custom.hide_out_of_stock}
							</div>
						</div>
					</div>

					<div
						style={{ width: "100%" }}
						className="flex">
						{(["all", "nearest"] as const).map((v, i) => {
							let isSelected = false
							let label = locales.custom.all_stores

							if (v === "all") {
								isSelected = sortByDistance == null
							} else {
								isSelected = sortByDistance != null
								label = locales.custom.nearest_stores
							}

							return (
								<div
									key={i}
									style={{
										backgroundColor: "none",
										width: "50%",
										border: "none",
										borderBottom: "2px solid #000",
										fontWeight: isSelected === true ? "bold" : undefined,
										color: isSelected === true ? "var(--color-green)" : "#000",
										borderColor:
											isSelected === true ? "var(--color-green)" : "#000"
									}}
									className="p-2 text-center text-xs sm:text-base cursor-pointer"
									onClick={() => {
										if (v === "all") {
											setSortByDistance(null)
											return
										}

										const _v: "nearest" = v
										// ThemeBuddy.toggleFullPageActivityIndicator(true)
										navigator.geolocation.getCurrentPosition(
											(pos) => {
												setSortByDistance(pos.coords)
												// ThemeBuddy.toggleFullPageActivityIndicator(false)
											},
											(e) => {
												// ThemeBuddy.toggleFullPageActivityIndicator(false)
												window.alert(e.message)
											}
										)
									}}>
									{label}
								</div>
							)
						})}
					</div>

					<div className="grid grid-cols-1 md:grid-cols-3 gap-4">
						{locations.map((location, i) => {
							const combined =
								location.name +
								JSON.stringify([location.address.city, location.address.address1])

							if (
								combined.toLowerCase().includes(filterQuery.toLowerCase()) ===
								false
							) {
								return null
							}

							return (
								<LocationCard
									key={i}
									location={location}
									hideIfOutOfStock={hideOutOfStock}
									onSelectLocation={props.onSelectLocation}
								/>
							)
						})}
					</div>
				</div>
			}
		/>
	)
}

export default LocationPickerModal
