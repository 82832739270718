import SVGIcon from "./SVGIcon"

type Props = {
	close: () => void
	title?: string
	body: string
	type: string
}
export default function Banner(props: Props) {
	let bgColor
	let borderColor
	if (props.type === "info") {
		bgColor = "#EBF9FC"
		borderColor = "rgb(152,198,252)"
	} else if (props.type === "warning") {
		bgColor = "#FFF5EA"
		borderColor = "rgb(225,184,120)"
	} else if (props.type === "error") {
		bgColor = "#FFF4F4"
		borderColor = "rgb(224,179,178)"
	} else if (props.type === "success") {
		bgColor = "#F1F8F5"
		borderColor = "rgb(149,201,180)"
	}
	return (
		<div
			style={{
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				border: `1px solid ${borderColor}`,
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				boxShadow: `0px 0px 6px ${borderColor}`,
				borderRadius: "12px",
				backgroundColor: bgColor
			}}>
			<div className="flex p-4 pl-8 justify-between" style={{ width: "100%" }}>
				<div>
					<b>{props.title}</b>
					<p>{props.body}</p>
				</div>
				<div style={{ cursor: "pointer" }} onClick={() => props.close()}>
					<SVGIcon name="x" />
				</div>
			</div>
		</div>
	)
}
