import { Fragment, useEffect, useState } from "react"
import { useGlobalStore } from "../../shared/useGlobalStore"
import SVGIcon from "../ui/SVGIcon"
import { storefrontAPI } from "~/shared/storefront-api/StorefrontAPI"
import { formatMoney } from "~/shared/utils"
import { App } from "~/types"
import ProductCard from "../product-card/ProductCard"
import { locales } from "../../shared/locales"
import CartItem from "./CartItem"
import CartInventoryStatus from "./CartInventoryStatus"

const SlideoutCart = () => {
	const cart = useGlobalStore((state) => state.cart)
	const showSlideoutCart = useGlobalStore((state) => state.showSlideoutCart)
	const [recomendedProducts, setRecomendedProducts] = useState<
		null | App.StorefrontAPI.Product[]
	>(null)
	const zIndex = 100000

	const [footerHeight, setFooterHeight] = useState(100)
	useEffect(() => {
		if (cart != null && cart.lines.length > 0) {
			const fetchProductRecommendationsRes = async () => {
				const mostRecentCartItemId = cart.lines[0].merchandise.product.id

				await storefrontAPI
					.getProductRecommendation({
						id: mostRecentCartItemId
					})
					.then((res) => {
						setRecomendedProducts(res)
					})
			}
			fetchProductRecommendationsRes()
		}
	}, [cart])

	if (cart == null) {
		return null
	}

	const freeShipLoadingWidthPercentage =
		100 - (1000 - parseInt(cart.cost.totalAmount.amount)) / 10
	return (
		<Fragment>
			<div
				onClick={() => useGlobalStore.setState({ showSlideoutCart: false })}
				style={{
					display: showSlideoutCart === true ? "block" : "none",
					position: "fixed",
					zIndex: zIndex - 1,
					left: 0,
					bottom: 0,
					top: 0,
					right: 0,
					height: "100%",
					backgroundColor: "rgba(0,0,0,0.7)"
				}}>
				<div
					className="hidden xl:flex product-cards-list-container scroller pb-2 space-x-2 overflow-x-auto px-[10px]"
					style={{
						position: "absolute",
						top: "50%",
						width: "calc(100vw - 500px)",
						transform: "translate(0,-50%)"
					}}>
					{recomendedProducts != null &&
						recomendedProducts.map((product, i) => {
							return (
								<div
									key={i}
									className="flex-shrink-0 product-card-wrapper max-w-[300px]">
									<ProductCard product={product} />
								</div>
							)
						})}
				</div>
			</div>
			<div
				style={{
					position: "fixed",
					zIndex: zIndex,
					top: 0,
					right: 0,
					height: "100%",
					width: "90vw",
					maxWidth: 500,
					// overflowY: 'auto',
					boxShadow: "1px 1px 15px rgba(0,0,0,0.2)",
					backgroundColor: "#fff",
					transition: "0.3s transform cubic-bezier(0.075, 0.82, 0.165, 1)",
					transform: `translate(${showSlideoutCart === true ? "0" : "100%"}, 0)`
				}}>
				<div className="flex flex-col justify-between h-full">
					<div
						className=""
						style={{
							position: "relative",
							top: 0,
							zIndex: 10,
							backgroundColor: "#fff",
							boxShadow: "0px 3px 8px rgba(0,0,0,0.1)",
							borderBottomRightRadius: 10,
							borderBottomLeftRadius: 10
						}}>
						<div
							className="text-center py-4 font-bold uppercase"
							style={{ fontSize: 20 }}>
							{locales.cart.general.title}
						</div>
						<div
							className="absolute"
							style={{
								right: 10,
								top: 16,
								borderRadius: "100%",
								border: "1px solid #efefef",
								padding: 4
							}}
							onClick={() => useGlobalStore.setState({ showSlideoutCart: false })}>
							<SVGIcon
								name="x"
								size={20}
								color={"#ef6f6c"}
							/>
						</div>
					</div>
					{cart.lines.length === 0 && (
						<div
							className="transform-centered text-center px-2"
							style={{
								width: "100%",
								margin: "0 auto",
								fontWeight: "bold",
								textTransform: "uppercase"
							}}>
							<h5 className="my-8">{locales.cart.general.empty}</h5>
						</div>
					)}
					<div className="overflow-y-auto space-y-4 px-[10px] py-4">
						{cart.lines.map((item) => {
							return (
								<CartItem
									key={item.id}
									item={item}
									cart={cart}
								/>
							)
						})}
					</div>
					<div
						ref={(ref) => {
							if (ref) {
								setFooterHeight((ref as HTMLElement).scrollHeight)
							}
						}}
						style={{
							// backgroundColor: "#cccccc20",
							width: "100%",
							position: "sticky",
							bottom: 0,
							borderTopRightRadius: 10,
							borderTopLeftRadius: 10,
							boxShadow: "0px 3px 8px rgba(0,0,0,0.5)"
						}}
						className="frosted-bg">
						<div className="p-[20px] space-y-2">
							<div
								className="flex justify-between font-bold"
								style={{ fontSize: "12px" }}>
								<div className="uppercase">Total:</div>
								{formatMoney({
									price: cart.cost.totalAmount
								})}
							</div>
							<div
								className="flex"
								style={{
									background:
										"linear-gradient(90deg, rgb(237, 184, 139) 0%, rgb(239, 111, 108) 100%)",
									width: "100%",
									height: "30px",
									position: "relative",
									borderRadius: 10
								}}>
								<div
									style={{
										backgroundColor: "#FFF",
										position: "absolute",
										width: "99%",
										height: "25px",
										top: "50%",
										left: "50%",
										transform: "translate(-50%,-50%)",
										borderRadius: 8
									}}>
									<div
										style={{
											background:
												"linear-gradient(90deg, rgb(237, 184, 139) 0%, rgb(239, 111, 108) 100%)",
											position: "absolute",
											width:
												freeShipLoadingWidthPercentage < 100
													? `${freeShipLoadingWidthPercentage}%`
													: "100%",
											height: "25px",
											top: "50%",
											transform: "translate(0%,-50%)",
											borderRadius: 8
										}}
									/>
								</div>
								<div
									className="absolute"
									style={{
										top: "50%",
										left: "50%",
										transform: "translate(-50%,-50%)"
									}}>
									{1000 - parseInt(cart.cost.totalAmount.amount) > 0 ? (
										<div
											style={{
												textAlign: "center",
												color: "#1c1d1d",
												fontSize: 14,
												fontWeight: 700
											}}>
											Du er ${1000 - parseInt(cart.cost.totalAmount.amount)}kr
											unna gratis frakt
										</div>
									) : (
										<div
											style={{
												textAlign: "center",
												color: "#fff",
												fontSize: 10,
												lineHeight: "10px",
												fontWeight: 700
											}}>
											Gratulerer! Du får gratis frakt
										</div>
									)}
								</div>
							</div>
							<CartInventoryStatus />
							<div
								onClick={() => {
									window.location.assign(cart.checkoutUrl)
								}}
								className="py-2"
								style={{
									backgroundColor: "#ef6f6c",
									textAlign: "center",
									color: "#fff",
									borderRadius: 12,
									cursor: "pointer"
								}}>
								Til kasse -{" "}
								{formatMoney({
									price: cart.cost.totalAmount
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default SlideoutCart
