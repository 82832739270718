import Header from "./Header"
import Footer from "./Footer"
import { App } from "../../types"
import AnnouncmentBar from "./AnnouncmentBar"
import LocationPickerModal from "../location-picker/LocationPickerModal"
import LocationService from "../location-picker/LocationService"
import UserWishlistView from "../UserWishlist"
import { CMS } from "../../sections/CMS"

type Props = {
	headerLogos: null | Array<CMS.MetaobjectField>
	footerLogo: null | CMS.ImageFileField
	isLoggedIn: boolean
	shop: App.StorefrontAPI.Shop
	mainMenu: null | App.StorefrontAPI.Menu
	mobileMenu: null | App.StorefrontAPI.Menu
	children: React.ReactNode
	footerMenu: null | App.StorefrontAPI.Menu
	activeSegmentedMenu: App.StorefrontAPI.MenuItem
}

export function Layout(props: Props) {
	return (
		<>
			<div className="flex flex-col min-h-screen">
				<div className="">
					<a
						href="#mainContent"
						className="sr-only">
						Skip to content
					</a>
				</div>
				<AnnouncmentBar />
				<Header
					headerLogos={props.headerLogos}
					isLoggedIn={props.isLoggedIn}
					mainMenu={props.mainMenu}
					shop={props.shop}
					activeSegmentedMenu={props.activeSegmentedMenu}
				/>
				<main
					role="main"
					id="mainContent"
					className="flex-grow">
					{props.children}
				</main>
				<LocationPickerModal
					onSelectLocation={(location: App.ShopifyLocation) => {
						LocationService.setSelectedLocation(location)
					}}
				/>
				<UserWishlistView />
			</div>
			<Footer
				shop={props.shop}
				menu={props.footerMenu}
				footerLogo={props.footerLogo}
			/>
		</>
	)
}
