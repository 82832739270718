import { useState } from "react"
import { locales } from "../shared/locales"
import { useGlobalStore } from "../shared/useGlobalStore"
import { formatMoney } from "../shared/utils"
import WishListService from "../swym/WishListService"
import { App } from "../types"
import BottomSheetModal from "./BottomSheetModal"
import SVGIcon from "./ui/SVGIcon"

const UserWishlistView = () => {
	const items = useGlobalStore((state) => {
		return state.userWishListItems
	})

	const isVisible = useGlobalStore((state) => {
		return state.userWishListIsVisible
	})

	const [isRemovingItem, setIsRemovingItem] = useState<null | App.WishlistItem>(null)

	if (isVisible === false) {
		return null
	}

	return (
		<BottomSheetModal
			id="UserWishlist"
			show={isVisible}
			onRequestHideCb={() => {
				useGlobalStore.setState({
					userWishListIsVisible: false
				})
			}}
			style={{
				height: "100%",
				minHeight: items.length === 0 ? "unset" : 500,
				maxHeight: items.length === 0 ? 175 : Math.min(600, items.length * 130 + 80)
			}}
			body={
				<div style={{ height: "100%", padding: 20 }}>
					<h4 className="text-center mb-3">{locales.custom.your_wishlist}</h4>

					{items.length === 0 && (
						<div
							style={{
								paddingBottom: 30
							}}
							data-test="user-wishlist-empty"
							className="text-center py-4 font-bold">
							{/* Your Wishlist is Empty */}
							Ønskelisten din er tom
						</div>
					)}

					<div
						style={{
							display: items.length === 0 ? "none" : "block",
							marginBottom: 20,
							// 80px to account for the the account buttons
							justifyContent: items.length <= 3 ? "center" : undefined
						}}
						className="space-y-3 flex flex-col overflow-y-auto">
						{items.map((item, i) => {
							return (
								<a
									data-test="user-wishlist-item-link"
									key={item.empi}
									href={item.du}
									style={{
										position: "relative",
										borderBottom: "1px solid #ddd",
										borderBottomWidth: items.length - 1 === i ? 0 : 1
									}}
									className="flex space-x-2 text-sm pb-3">
									<img
										style={{
											aspectRatio: "3/4",
											border: "1px solid #efefef",
											borderRadius: "calc(var(--global-border-radius) * 0.5)",
											// background: `url(${modifyShopifyImageSrc(item.iu, { width: 4 })})`,
											width: 100,
											objectFit: "contain"
										}}
										src={item.iu}
									/>

									<div
										style={{
											// needed to avoid overlapping with the remove button
											paddingRight: 30
										}}>
										<div
											data-test="wishlist-product-title"
											className="uppercase font-bold">
											{item.dt || item.ct}
										</div>
										<div>
											{formatMoney({
												// since it's already divided
												price: {
													amount: item.pr.toFixed(2).toString(),
													currencyCode: "NOK"
												}
											})}
										</div>
									</div>

									<div
										style={{
											zIndex: 1,
											position: "absolute",
											top: 2,
											right: 0,
											padding: 4,
											borderRadius: "100%",
											border: "1px solid #ddd"
										}}
										data-test="user-wishlist-item-remove-button"
										className="cursor-pointer"
										onClick={(e) => {
											e.preventDefault()
											const itemToRemove = item
											setIsRemovingItem(itemToRemove)
											WishListService.removeItemFromWishList({
												empi: itemToRemove.empi,
												epi: itemToRemove.epi,
												du: itemToRemove.du
											})
										}}>
										{isRemovingItem === item && (
											<div className="transform-centered">
												<div className="loader" />
											</div>
										)}
										<SVGIcon
											style={{
												visibility:
													isRemovingItem === item ? "hidden" : "visible"
											}}
											name="trash"
											color="red"
											strokeWidth={2}
											size={20}
										/>
									</div>
								</a>
							)
						})}
					</div>
				</div>
			}
			// footer={
			// 	useGlobalStore.getState().__shopify_global_shop__.isUserLoggedIn ===
			// 	true ? null : (
			// 		<div
			// 			style={{
			// 				width: "100%",
			// 				backgroundColor: "white",
			// 				borderTop: "1px solid #efefef"
			// 			}}
			// 			className="flex space-x-2 text-center p-4">
			// 			<a
			// 				style={{
			// 					fontSize: 11,
			// 					padding: "8px 5px"
			// 				}}
			// 				className="button w-1/2"
			// 				href="/account">
			// 				{locales.customer.login.title}
			// 			</a>
			// 			<a
			// 				style={{
			// 					fontSize: 11,
			// 					padding: "8px 5px"
			// 				}}
			// 				className="button w-1/2"
			// 				href="/account/register">
			// 				{locales.customer.register.title}
			// 			</a>
			// 		</div>
			// 	)
			// }
		/>
	)
}

export default UserWishlistView
