import { useEffect, useState } from "react"
import { storefrontAPI } from "../../shared/storefront-api/StorefrontAPI"
import { useGlobalStore } from "../../shared/useGlobalStore"
import { formatMoney } from "../../shared/utils"
import { App } from "../../types"
import LocationService from "../location-picker/LocationService"
import Spinner from "../ui/Spinner"
import SVGIcon from "../ui/SVGIcon"
import { InventoryStatus } from "./InventoryStatus"

type Props = {
	cart: App.InitializedCart
	item: App.InitializedCart["lines"][0]
}
const CartItem = (props: Props) => {
	const { item, cart } = props
	const { image } = props.item.merchandise

	const [inProgress, setInProgress] = useState<null | {
		type: "quantity" | "delete"
		lineItemId: string
	}>(null)

	const [status, setStatus] = useState<null | {
		shippable: boolean
		pickUp: boolean
	}>(null)
	const selectedLocation = useGlobalStore((state) => state.selectedLocation)

	const [inventoryItem, setInventoryItem] = useState<
		undefined | null | App.ShopifyProductInventoryData["inventoryItems"][0]
	>(undefined)

	useEffect(() => {
		void LocationService.getInventoryItemForCartItem({ item: item }).then(
			(inventoryItem) => {
				if (inventoryItem == null) {
					return
				}

				const inventoryStatus = LocationService.getVariantInventoryStatus({
					inventoryItem: inventoryItem,
					location: selectedLocation
				})
				setStatus(inventoryStatus)
				setInventoryItem(inventoryItem)
			}
		)
	}, [item, selectedLocation])

	return (
		<div
			className="p-4 space-y-4"
			style={{
				boxShadow: "inset 1px 1px 8px #00000029",
				borderRadius: 10
			}}>
			<div className="flex flex-row-reverse justify-between items-start">
				<div>
					<img
						className="object-cover object-center border rounded"
						src={image.url}
						style={{
							aspectRatio: 4 / 5,
							maxWidth: "110px"
						}}
					/>
				</div>
				<div className="space-y-2">
					<div
						data-test="cart-product-title"
						className="font-bold max-md:text-[14px]">
						{item.merchandise.product.title}
					</div>
					{item.merchandise.product.compareAtPriceRange.maxVariantPrice.amount !==
					"0.0" ? (
						<div className="flex space-x-2">
							<div
								style={{
									color: "gray",
									textDecoration: "line-through"
								}}>
								{formatMoney({
									price: item.merchandise.product.compareAtPriceRange
										.maxVariantPrice
								})}
							</div>
							<div
								style={{
									color: "var(--color-sale-price)"
								}}>
								{formatMoney({
									price: item.merchandise.product.priceRange.maxVariantPrice
								})}
							</div>
						</div>
					) : item.merchandise.product.priceRange.minVariantPrice.amount ===
					  item.merchandise.product.priceRange.maxVariantPrice.amount ? (
						formatMoney({
							price: {
								...item.merchandise.product.priceRange.maxVariantPrice,
								amount: item.merchandise.product.priceRange.maxVariantPrice.amount
							}
						})
					) : (
						<div>
							{formatMoney({
								price: item.merchandise.product.priceRange.minVariantPrice
							})}
							-
							{formatMoney({
								price: item.merchandise.product.priceRange.maxVariantPrice
							})}
						</div>
					)}
					<div>
						{item.merchandise.selectedOptions.map((option) => {
							return (
								<p
									key={option.name}
									style={{ color: "#ccc" }}>
									<span data-test={option.value}>{option.value}</span>
								</p>
							)
						})}
					</div>
					<div className="flex">
						<div
							className="flex items-center"
							style={{
								border: "1px solid #e8e8e8",
								width: "fit-content",
								borderRadius: 50
							}}>
							<div
								className="px-3 flex items-center cursor-pointer"
								style={{ height: "100%" }}
								onClick={async () => {
									setInProgress({
										type: "delete",
										lineItemId: item.id
									})
									await storefrontAPI.updateCartItemQty({
										cart: cart,
										lineItemId: item.id,
										quantity: Math.max(0, item.quantity - 1)
									})
									setInProgress(null)
								}}>
								<SVGIcon
									name="minus"
									size={12}
								/>
							</div>
							<div
								className="px-3 py-1"
								style={{
									borderLeft: "1px solid #e8e8e8",
									borderRight: "1px solid #e8e8e8"
								}}>
								{item.quantity}
							</div>
							<div
								className="px-3 flex items-center cursor-pointer"
								style={{ height: "100%" }}
								onClick={async () => {
									setInProgress({
										type: "delete",
										lineItemId: item.id
									})
									await storefrontAPI.updateCartItemQty({
										cart: cart,
										lineItemId: item.id,
										quantity: item.quantity + 1
									})
									setInProgress(null)
								}}>
								<SVGIcon
									name="plus"
									size={12}
								/>
							</div>
						</div>
						<div
							onClick={async () => {
								setInProgress({
									type: "delete",
									lineItemId: item.id
								})
								await storefrontAPI.removeCartItem({
									cart: cart,
									variantId: item.id
								})
								setInProgress(null)
							}}
							style={{
								cursor: "pointer",
								marginLeft: "1rem",
								border: "1px solid rgb(239, 239, 239)",
								boxShadow: "rgb(0 0 0 / 5%) 0px 0px 8px",
								borderRadius: "50%"
							}}
							className="flex items-center p-2">
							{inProgress?.type === "delete" &&
							inProgress.lineItemId === item.id ? (
								<Spinner />
							) : (
								<SVGIcon
									name="trash"
									size={20}
								/>
							)}
						</div>
					</div>
				</div>
			</div>

			{status != null && (
				<InventoryStatus
					className="col-span-12"
					inProgress={inventoryItem === undefined}
					shippable={status.shippable}
					pickUp={status.pickUp}
				/>
			)}
		</div>
	)
}
export default CartItem
