import { useLocation, useNavigate, useNavigation } from "@remix-run/react"
import { useRef } from "react"
import { App } from "../types"
import SVGIcon from "./ui/SVGIcon"

// country code regex
const CC_REGEX = /^[a-z]{2}$/i

// flag emoji use 2 regional indicator symbols, and each symbol is 2 chars
const FLAG_LENGTH = 4

// offset between uppercase ascii and regional indicator symbols
const OFFSET = 127397

export function countryCodeEmoji(cc: string) {
	if (!CC_REGEX.test(cc)) {
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		throw new TypeError(
			`cc argument must be an ISO 3166-1 alpha-2 string, but got ${cc} instead.`
		)
	}

	// @ts-expect-error
	const codePoints = [...cc.toUpperCase()].map((c) => c.codePointAt() + OFFSET)
	return String.fromCodePoint(...codePoints)
}

export function emojiCountryCode(flag: string | any[]) {
	if (flag.length !== FLAG_LENGTH) {
		throw new TypeError(
			`flag argument must be a flag emoji, but got '${typeof flag}}' instead.`
		)
	}

	const codePoints = [...flag].map((c) => c.codePointAt() - OFFSET)
	return String.fromCodePoint(...codePoints)
}

type Props = {
	className?: string
	selectedLocal: {
		country: string
		currency: string
		label: string
	}
	localization: App.Loader.Route.Root["localization"]
}

const Localization = (props: Props) => {
	const { localization, selectedLocal } = props

	if (localization == null) {
		console.error("localization is null")
		return null
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const location = useLocation()

	const { availableCountries } = localization

	if (availableCountries.length === 0) {
		return null
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const LocalizationForm = useRef<HTMLFormElement>(null)
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const localizationInput = useRef<HTMLInputElement>(null)

	const countryFlagEmoji = countryCodeEmoji(selectedLocal.country)

	const value = `${countryFlagEmoji} ${selectedLocal.label.split("(")[0].trim()} (${
		selectedLocal.currency
	})`

	return (
		<form
			action={`/localization`}
			method="post"
			id="Localization"
			ref={LocalizationForm}>
			<div
				className={"relative " + props.className || ""}
				style={{
					width: "fit-content",
					borderRadius: 5,
					border: "2px solid #000"
				}}>
				<select
					style={{
						border: "none",
						fontSize: 12,
						maxWidth: 150,
						cursor: "pointer",
						padding: "6px 26px 6px 6px",
						backgroundColor: "transparent"
					}}
					defaultValue={selectedLocal.country}
					onChange={(e) => {
						const isoCode = e.currentTarget.value
						const country = availableCountries.find((country) => {
							return country.isoCode === isoCode
						})

						// Guard
						if (
							country != null &&
							window.confirm(
								`Change to: ${country.name} (${country.currency.isoCode} ${country.currency.symbol})?`
							) !== true
						) {
							return
						}
						localizationInput.current?.setAttribute("value", isoCode)
						LocalizationForm.current?.submit()
					}}>
					{availableCountries.map((country, i) => {
						const flagEmoji = countryCodeEmoji(country.isoCode)
						return (
							<option
								key={i}
								value={country.isoCode}>
								{flagEmoji} {country.name} ({country.currency.isoCode}{" "}
								{country.currency.symbol})
							</option>
						)
					})}
				</select>
				<SVGIcon
					style={{
						top: "50%",
						right: 6,
						pointerEvents: "none",
						position: "absolute",
						transform: "translateY(-50%)"
					}}
					name="chevron-down"
					strokeWidth={2}
					size={18}
				/>
			</div>
			<input
				type="hidden"
				name="redirectTo"
				value={`${location.pathname}${location.search}`}
			/>
			<input
				ref={localizationInput}
				type="hidden"
				name="country_code"
				value={selectedLocal.country}
			/>
		</form>
	)
}
export default Localization
