import clsx from "clsx"
import { useRef } from "react"
import { useScroll } from "react-use"
import { flattenConnection, Image, Money } from "@shopify/hydrogen"
import { Button, Heading, Text, Link } from "~/components"
import { getInputStyleClasses } from "~/shared/utils"
import type {
	Cart as CartType,
	CartCost,
	CartLine,
	CartLineUpdateInput
} from "@shopify/hydrogen/storefront-api-types"
import { useFetcher } from "@remix-run/react"
import { CartAction } from "~/shared/type"
import SVGIcon from "../ui/SVGIcon"

type Layouts = "page" | "drawer"

export function Cart({
	layout,
	onClose,
	cart
}: {
	layout: Layouts
	onClose?: () => void
	cart: CartType | null
}) {
	const linesCount = Boolean(cart?.lines?.edges?.length || 0)

	return (
		<>
			<CartEmpty
				hidden={linesCount}
				onClose={onClose}
				layout={layout}
			/>
			<CartDetails
				cart={cart}
				layout={layout}
			/>
		</>
	)
}

export function CartDetails({
	layout,
	cart
}: {
	layout: Layouts
	cart: CartType | null
}) {
	// @todo: get optimistic cart cost
	const isZeroCost = !cart || cart?.cost?.subtotalAmount?.amount === "0.0"

	const container = {
		drawer: "grid grid-cols-1 h-screen-no-nav grid-rows-[1fr_auto]",
		page: "w-full pb-12 grid md:grid-cols-2 md:items-start gap-8 md:gap-8 lg:gap-12"
	}

	return (
		<div className={container[layout]}>
			<CartLines
				lines={cart?.lines}
				layout={layout}
			/>
			{!isZeroCost && (
				<CartSummary
					cost={cart.cost}
					layout={layout}>
					<CartDiscounts discountCodes={cart.discountCodes} />
					<CartCheckoutActions checkoutUrl={cart.checkoutUrl} />
				</CartSummary>
			)}
		</div>
	)
}

/**
 * Temporary discount UI
 * @param discountCodes the current discount codes applied to the cart
 * @todo rework when a design is ready
 */
function CartDiscounts({ discountCodes }: { discountCodes: CartType["discountCodes"] }) {
	const codes = discountCodes?.map(({ code }) => code).join(", ") || null

	return (
		<>
			{/* Have existing discount, display it with a remove option */}
			<dl className={codes ? "grid" : "hidden"}>
				<div className="flex items-center justify-between font-medium">
					<Text as="dt">Discount(s)</Text>
					<div className="flex items-center justify-between">
						<UpdateDiscountForm>
							<button>
								<SVGIcon name="trash" />
							</button>
						</UpdateDiscountForm>
						<Text as="dd">{codes}</Text>
					</div>
				</div>
			</dl>

			{/* No discounts, show an input to apply a discount */}
			<UpdateDiscountForm>
				<div
					className={clsx(
						codes ? "hidden" : "flex",
						"items-center gap-4 justify-between text-copy"
					)}>
					<input
						className={getInputStyleClasses()}
						type="text"
						name="discountCode"
						placeholder="Discount code"
					/>
					<button className="flex justify-end font-medium whitespace-nowrap">
						Apply Discount
					</button>
				</div>
			</UpdateDiscountForm>
		</>
	)
}

function UpdateDiscountForm({ children }: { children: React.ReactNode }) {
	const fetcher = useFetcher()
	return (
		<fetcher.Form
			action="/cart"
			method="post">
			<input
				type="hidden"
				name="cartAction"
				value={CartAction.UPDATE_DISCOUNT}
			/>
			{children}
		</fetcher.Form>
	)
}

function CartLines({
	layout = "drawer",
	lines: cartLines
}: {
	layout: Layouts
	lines: CartType["lines"] | undefined
}) {
	const currentLines = cartLines ? flattenConnection(cartLines) : []
	const scrollRef = useRef(null)
	const { y } = useScroll(scrollRef)

	const className = clsx([
		y > 0 ? "border-t" : "",
		layout === "page"
			? "flex-grow md:translate-y-4"
			: "px-6 pb-6 sm-max:pt-2 overflow-auto transition md:px-12"
	])

	return (
		<section
			ref={scrollRef}
			aria-labelledby="cart-contents"
			className={className}>
			<ul className="grid gap-6 md:gap-10">
				{currentLines.map((line) => (
					<CartLineItem
						key={line.id}
						line={line as CartLine}
					/>
				))}
			</ul>
		</section>
	)
}

function CartCheckoutActions({ checkoutUrl }: { checkoutUrl: string }) {
	if (!checkoutUrl) return null

	return (
		<div className="flex flex-col mt-2">
			<a
				href={checkoutUrl}
				target="_self">
				<Button
					as="span"
					width="full">
					Continue to Checkout
				</Button>
			</a>
			{/* @todo: <CartShopPayButton cart={cart} /> */}
		</div>
	)
}

function CartSummary({
	cost,
	layout,
	children = null
}: {
	children?: React.ReactNode
	cost: CartCost
	layout: Layouts
}) {
	const summary = {
		drawer: "grid gap-4 p-6 border-t md:px-12",
		page: "sticky top-nav grid gap-6 p-4 md:px-6 md:translate-y-4 bg-primary/5 rounded w-full"
	}

	return (
		<section
			aria-labelledby="summary-heading"
			className={summary[layout]}>
			<h2
				id="summary-heading"
				className="sr-only">
				Order summary
			</h2>
			<dl className="grid">
				<div className="flex items-center justify-between font-medium">
					<Text as="dt">Subtotal</Text>
					<Text
						as="dd"
						data-test="subtotal">
						{cost?.subtotalAmount?.amount ? (
							<Money data={cost?.subtotalAmount} />
						) : (
							"-"
						)}
					</Text>
				</div>
			</dl>
			{children}
		</section>
	)
}

function CartLineItem({ line }: { line: CartLine }) {
	if (!line?.id) return null

	const { id, quantity, merchandise } = line

	if (typeof quantity === "undefined" || !merchandise?.product) return null

	return (
		<li
			key={id}
			className="flex gap-4">
			<div className="flex-shrink">
				{merchandise.image && (
					<Image
						width={220}
						height={220}
						data={merchandise.image}
						className="object-cover object-center w-24 h-24 border rounded md:w-28 md:h-28"
						alt={merchandise.title}
					/>
				)}
			</div>

			<div className="flex justify-between flex-grow">
				<div className="grid gap-2">
					<Heading
						as="h3"
						size="copy">
						{merchandise?.product?.handle ? (
							<Link to={`/products/${merchandise.product.handle}`}>
								{merchandise?.product?.title || ""}
							</Link>
						) : (
							<Text>{merchandise?.product?.title || ""}</Text>
						)}
					</Heading>

					<div className="grid pb-2">
						{(merchandise?.selectedOptions || []).map((option) => (
							<Text
								color="subtle"
								key={option.name}>
								{option.name}: {option.value}
							</Text>
						))}
					</div>

					<div className="flex items-center gap-2">
						<div className="flex justify-start text-copy">
							<CartLineQuantityAdjust line={line} />
						</div>
						<ItemRemoveButton lineIds={[id]} />
					</div>
				</div>
				<Text>
					<CartLinePrice
						line={line}
						as="span"
					/>
				</Text>
			</div>
		</li>
	)
}

function ItemRemoveButton({ lineIds }: { lineIds: CartLine["id"][] }) {
	const fetcher = useFetcher()

	return (
		<fetcher.Form
			action="/cart"
			method="post">
			<input
				type="hidden"
				name="cartAction"
				value={CartAction.REMOVE_FROM_CART}
			/>
			<input
				type="hidden"
				name="linesIds"
				value={JSON.stringify(lineIds)}
			/>
			<button
				className="flex items-center justify-center w-10 h-10 border rounded"
				type="submit">
				<span className="sr-only">Remove</span>
				<SVGIcon name="trash" />
			</button>
		</fetcher.Form>
	)
}

function CartLineQuantityAdjust({ line }: { line: CartLine }) {
	if (!line || typeof line?.quantity === "undefined") return null
	const { id: lineId, quantity } = line
	const prevQuantity = Number(Math.max(0, quantity - 1).toFixed(0))
	const nextQuantity = Number((quantity + 1).toFixed(0))

	return (
		<>
			<label
				htmlFor={`quantity-${lineId}`}
				className="sr-only">
				Quantity, {quantity}
			</label>
			<div className="flex items-center border rounded">
				<UpdateCartButton lines={[{ id: lineId, quantity: prevQuantity }]}>
					<button
						name="decrease-quantity"
						aria-label="Decrease quantity"
						className="w-10 h-10 transition text-primary/50 hover:text-primary disabled:text-primary/10"
						value={prevQuantity}
						disabled={quantity <= 1}>
						<span>&#8722;</span>
					</button>
				</UpdateCartButton>

				<div
					className="px-2 text-center"
					data-test="item-quantity">
					{quantity}
				</div>

				<UpdateCartButton lines={[{ id: lineId, quantity: nextQuantity }]}>
					<button
						className="w-10 h-10 transition text-primary/50 hover:text-primary"
						name="increase-quantity"
						value={nextQuantity}
						aria-label="Increase quantity">
						<span>&#43;</span>
					</button>
				</UpdateCartButton>
			</div>
		</>
	)
}

function UpdateCartButton({
	children,
	lines
}: {
	children: React.ReactNode
	lines: CartLineUpdateInput[]
}) {
	const fetcher = useFetcher()

	return (
		<fetcher.Form
			action="/cart"
			method="post">
			<input
				type="hidden"
				name="cartAction"
				value={CartAction.UPDATE_CART}
			/>
			<input
				type="hidden"
				name="lines"
				value={JSON.stringify(lines)}
			/>
			{children}
		</fetcher.Form>
	)
}

function CartLinePrice({
	line,
	priceType = "regular",
	...passthroughProps
}: {
	line: CartLine
	priceType?: "regular" | "compareAt"
	[key: string]: any
}) {
	if (!line?.cost?.amountPerQuantity || !line?.cost?.totalAmount) return null

	const moneyV2 =
		priceType === "regular"
			? line.cost.totalAmount
			: line.cost.compareAtAmountPerQuantity

	if (moneyV2 == null) {
		return null
	}

	return (
		<Money
			withoutTrailingZeros
			{...passthroughProps}
			data={moneyV2}
		/>
	)
}

export function CartEmpty({
	hidden = false,
	layout = "drawer",
	onClose
}: {
	hidden: boolean
	layout?: Layouts
	onClose?: () => void
}) {
	const scrollRef = useRef(null)
	const { y } = useScroll(scrollRef)

	const container = {
		drawer: clsx([
			"content-start gap-4 px-6 pb-8 transition overflow-y-scroll md:gap-12 md:px-12 h-screen-no-nav md:pb-12",
			y > 0 ? "border-t" : ""
		]),
		page: clsx([
			hidden ? "" : "grid",
			`pb-12 w-full md:items-start gap-4 md:gap-8 lg:gap-12`
		])
	}

	return (
		<div
			ref={scrollRef}
			className={container[layout]}
			hidden={hidden}>
			<section className="grid gap-6">
				<Text format>
					Looks like you haven&rsquo;t added anything yet, let&rsquo;s get you
					started!
				</Text>
				<div>
					<Button onClick={onClose}>Continue shopping</Button>
				</div>
			</section>
		</div>
	)
}
