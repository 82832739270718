import SlideoutCart from "../cart/SlideoutCart"
import SlideoutMenu from "./SlideoutMenu"
import { App } from "../../types"
import InlineSearchBox from "../../modules/search/InlineSearchBox"
import { Link } from "../ui/Link"
import { useMatches } from "@remix-run/react"
import { useGlobalStore } from "~/shared/useGlobalStore"
import SVGIcon from "../ui/SVGIcon"
import Localization from "../Localization"
import { useEffect, useRef, useState } from "react"
import { animate, stagger } from "motion"
import { CMS } from "../../sections/CMS"

type Props = {
	headerLogos: null | Array<CMS.MetaobjectField>
	isLoggedIn: boolean
	shop: App.StorefrontAPI.Shop
	mainMenu: null | App.StorefrontAPI.Menu
	activeSegmentedMenu: App.StorefrontAPI.MenuItem
}

const Header = (props: Props) => {
	const { shop, headerLogos } = props
	const [root] = useMatches()
	const cart = useGlobalStore((state) => state.cart)
	const data: App.Loader.Route.Root = root.data
	const localization = data.localization

	let activeParentMenu: App.StorefrontAPI.MenuItem = useGlobalStore((state) => {
		return state.activeSegmentMenu
	})
	if (activeParentMenu == null) {
		activeParentMenu = props.activeSegmentedMenu
	}

	const [state, setState] = useState<{
		activeSubMenu: null | App.StorefrontAPI.MenuItem
		activeParentElem: null | HTMLAnchorElement
	}>({
		activeSubMenu: null,
		activeParentElem: null
	})
	const animateOnOpenClassName = "animate-on-open"
	const activeSubMenuContaierElem = useRef<HTMLDivElement>(null)
	const menuList = useRef<HTMLDivElement>(null)
	useEffect(() => {
		const { activeSubMenu, activeParentElem } = state
		if (
			activeSubMenu == null ||
			activeSubMenu.items == null ||
			activeSubMenu.items.length === 0
		) {
			return
		}

		if (activeParentElem == null) {
			console.error("FD: activeParentElem is not defined")
		} else {
			const left = activeParentElem.getBoundingClientRect().left

			if (menuList.current == null) {
				console.error("FD: Child menu list is not found though it must ")
			} else {
				menuList.current.style.transform = `translateX(${left}px)`
			}
		}

		if (activeSubMenuContaierElem.current === null) {
			return
		}
		const scrollHeight = activeSubMenuContaierElem.current.scrollHeight
		animate(
			activeSubMenuContaierElem.current,
			{
				maxHeight: scrollHeight + "px"
			},
			{
				duration: 0.2,
				easing: [0.67, 0.77, 0.83, 1]
			}
		)

		animate(
			activeSubMenuContaierElem.current.querySelectorAll(`.${animateOnOpenClassName}`),
			{
				opacity: [0.3, 1],
				y: [-5, 5]
			},
			{
				delay: stagger(0.1)
			}
		)
	})

	type LogoFields = {
		logo_women: CMS.ImageFileField
		logo_men: CMS.ImageFileField
		logo_kids: CMS.ImageFileField
	}
	// TODO: use getmetaobject
	function getFields<SF extends LogoFields>(
		fields: Array<CMS.MetaobjectField>
	): CMS.FieldsMap<SF> {
		const fieldsMap: Partial<CMS.FieldsMap<SF>> = {}
		fields.forEach((field) => {
			const key = field.key as keyof SF
			fieldsMap[key] = field as any
		})

		return fieldsMap as CMS.FieldsMap<SF>
	}

	const { logo_women, logo_men, logo_kids } = getFields(headerLogos!)

	const renderLogo = () => {
		if (headerLogos == null) {
			return null
		}

		let logoSrc = logo_women.reference?.image.url
		if (activeParentMenu.title.toLowerCase() === "herre") {
			logoSrc = logo_men.reference?.image.url
		}
		if (activeParentMenu.title.toLowerCase() === "barn") {
			logoSrc = logo_kids.reference?.image.url
		}

		return (
			<Link to={activeParentMenu.url != null ? activeParentMenu.url : "/"}>
				<img
					className="h-[48px] lg:h-[60px]"
					style={{
						width: "auto",
						padding: "12px 0",
						maxHeight: "100%"
					}}
					src={logoSrc}
				/>
			</Link>
		)
	}

	const renderSubMenu = () => {
		const { activeSubMenu } = state
		if (
			activeSubMenu == null ||
			activeSubMenu.items == null ||
			activeSubMenu.items.length === 0
		) {
			return null
		}

		return (
			<div
				ref={activeSubMenuContaierElem}
				style={{
					zIndex: 1,
					top: "100%",
					// this seems to align it better with the header though this should be addressed in a better way
					left: -1,
					width: "calc(100% + 2px)",
					position: "absolute",
					// maxHeight: 0,
					maxHeight: 1000,
					overflow: "hidden",
					textAlign: "left",
					backgroundColor: "white",
					boxShadow:
						"rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
					border: "1px solid #dddddd",
					borderTop: "none",
					borderBottomLeftRadius: "var(--global-border-radius)",
					borderBottomRightRadius: "var(--global-border-radius)"
				}}>
				<div
					style={{
						padding: "20px 0",
						display: "inline-block"
					}}
					ref={menuList}
					id="MenuList"
					className="space-y-4">
					{activeSubMenu.items.map((item, i) => {
						const hasChildren = item.items && item.items.length > 0
						return (
							<div
								className={`menu-list ${animateOnOpenClassName}`}
								key={i}>
								<Link
									to={item.url != null ? item.url : ""}
									style={{
										fontSize: 14,
										display: "block",
										marginBottom: 10
									}}
									className="font-bold">
									{item.title}
								</Link>

								{hasChildren === true && (
									<div className="space-y-3">
										{item.items?.map((item) => {
											return (
												<a
													style={{
														fontSize: 14,
														display: "block",
														fontWeight: 300
													}}
													href={item.url != null ? item.url : ""}>
													{item.title}
												</a>
											)
										})}
									</div>
								)}
							</div>
						)
					})}
				</div>
			</div>
		)
	}

	const renderSegmentedMenu = () => {
		return (
			<div
				style={{
					borderBottom: "1px solid #efefef"
				}}>
				<div className="container">
					<div className="flex items-center justify-center space-x-4 py-2">
						{props.mainMenu != null &&
							props.mainMenu.items.map((item, i) => {
								return (
									<Link
										style={{
											textTransform: "uppercase",
											fontFamily: "var(--heading-font-family)",
											cursor: "pointer",
											lineHeight: "22.4px",
											borderBottom:
												activeParentMenu.title === item.title
													? "2px solid black"
													: "2px solid transparent"
										}}
										to={item.url != null ? item.url : "#"}
										onClick={() => {
											useGlobalStore.setState((prevState) => {
												prevState.activeSegmentMenu = item
												prevState.activeSegmentMenuTitle = item.title
												return {
													...prevState
												}
											})
										}}
										key={i}>
										{item.title}
									</Link>
								)
							})}
					</div>
				</div>
			</div>
		)
	}

	const renderActiveMenu = () => {
		const { activeSubMenu } = state

		return (
			<div
				className="hidden lg:flex col-span-6 justify-center items-center"
				onMouseLeave={() => {
					if (state.activeSubMenu == null) {
						return
					}
					setState({
						activeSubMenu: null,
						activeParentElem: null
					})
				}}>
				{activeParentMenu.items?.map((item, i) => {
					const hasChildren = item.items != null && item.items.length > 0
					return (
						<Link
							className={`nav-top-link ${hasChildren === true ? "space-x-1" : ""}`}
							onMouseEnter={(e) => {
								if (item.items == null || item.items.length === 0) {
									if (activeSubMenu == null && activeParentMenu == null) {
										return
									}
									setState({
										activeSubMenu: null,
										activeParentElem: null
									})
									return
								}

								setState({
									activeSubMenu: item,
									activeParentElem: e.currentTarget
								})
							}}
							key={i}
							to={item.url != null ? item.url : "#"}>
							<div>{item.title}</div>
							{hasChildren === true && (
								<SVGIcon
									name="chevron-down"
									size={18}
									viewBox="0 0 24 24"
								/>
							)}
						</Link>
					)
				})}
				{renderSubMenu()}
			</div>
		)
	}

	return (
		<>
			<header
				style={{
					top: 0,
					zIndex: 10000,
					borderTop: "none",
					position: "sticky",
					border: "1px solid #dddddd",
					backgroundColor: "rgba(255, 255, 255)",
					boxShadow: "0 0 2rem rgba(1, 0, 31, 0.1)",
					borderBottomLeftRadius: "var(--global-border-radius)",
					borderBottomRightRadius: "var(--global-border-radius)"
				}}
				role="banner">
				{renderSegmentedMenu()}

				<div className="container">
					<div className="grid grid-cols-12 gap-0 xl:gap-4">
						<div className="col-span-5 lg:col-span-3 flex items-center">
							<div
								className="lg:hidden"
								onClick={() => {
									useGlobalStore.setState({
										showSlideoutMenu: true
									})
								}}>
								<SVGIcon name="burger" />
							</div>
							{headerLogos == null ? (
								<Link
									className="font-bold"
									style={{
										padding: "12px 0"
									}}
									to={"/"}>
									{shop.name}
								</Link>
							) : (
								renderLogo()
							)}
						</div>
						{renderActiveMenu()}
						<div className="col-span-7 lg:col-span-3 flex space-x-2 justify-end items-center">
							{localization.availableCountries.length > 1 && (
								<div className="hidden lg:flex items-center">
									<Localization
										localization={localization}
										selectedLocal={data.selectedLocale}
									/>
								</div>
							)}

							<InlineSearchBox />

							<div
								className="cursor-pointer"
								onClick={() => {
									useGlobalStore.setState({
										locationPickerModal: {
											show: true
										}
									})
								}}>
								<SVGIcon
									name="location"
									size={24}
									strokeWidth={2}
								/>
							</div>

							<div
								className="relative cursor-pointer"
								onClick={() => {
									useGlobalStore.setState({
										userWishListIsVisible: true
									})
								}}>
								<SVGIcon
									name="heart"
									size={24}
									strokeWidth={2}
								/>
							</div>

							<Link
								className="relative hidden lg:block"
								to="/account">
								<SVGIcon
									name="user"
									strokeWidth={2}
									size={24}
								/>
							</Link>

							<div
								className="relative cursor-pointer"
								onClick={() => {
									useGlobalStore.setState({
										showSlideoutCart:
											!useGlobalStore.getState().showSlideoutCart
									})
								}}>
								<SVGIcon
									name="shopping-cart"
									size={24}
									strokeWidth={2}
								/>
								<div
									className="absolute"
									style={{
										top: -2,
										right: -6,
										borderRadius: "50%",
										backgroundColor: "var(--color-cta)",
										width: "15px",
										height: 15
									}}>
									<div
										className="transform-centered"
										style={{
											textAlign: "center",
											color: "white",
											fontSize: 12
										}}>
										{cart != null ? cart.totalQuantity : 0}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>

			<SlideoutCart />

			{data.layout.mobileMenu != null && (
				<SlideoutMenu
					logoSrc={logo_women}
					mobileMenu={data.layout.mobileMenu}
					shopName={shop.name}
					activeSegmentedMenu={props.activeSegmentedMenu}
				/>
			)}
		</>
	)
}

export default Header
