import { useState } from "react"
import { useFetcher } from "@remix-run/react"
import { Except } from "type-fest"

import { Button, ProductCard, Link } from "~/components"
import { App } from "../types"

export function ProductGrid({
	url,
	collection,
	...props
}: {
	url: string
	collection: {
		products: Except<App.StorefrontAPI.Collection<true>["products"], "filters">
	}
}) {
	const [initialProducts, setInitialProducts] = useState(
		collection?.products?.nodes || []
	)

	const [nextPage, setNextPage] = useState(collection?.products?.pageInfo?.hasNextPage)
	const [endCursor, setEndCursor] = useState(collection?.products?.pageInfo?.endCursor)
	const [products, setProducts] = useState(initialProducts)

	// props have changes, reset component state
	const productProps = collection?.products?.nodes || []
	if (initialProducts !== productProps) {
		setInitialProducts(productProps)
		setProducts(productProps)
	}

	const fetcher = useFetcher()

	function fetchMoreProducts() {
		fetcher.load(`${url}?index&cursor=${endCursor}`)
	}

	// useEffect(() => {
	// 	if (!fetcher.data) return
	// 	const { collection } = fetcher.data
	// 	setProducts((prev: App.StorefrontAPI.Collection['products']) => [...prev, ...collection.])
	// 	setNextPage(collection.products.pageInfo.hasNextPage)
	// 	setEndCursor(collection.products.pageInfo.endCursor)
	// }, [fetcher.data])

	const haveProducts = initialProducts.length > 0

	if (!haveProducts) {
		return (
			<>
				<p>No products found on this collection</p>
				<Link to="/products">
					<p className="underline">Browse catalog</p>
				</Link>
			</>
		)
	}

	return (
		<>
			<div className="grid grid-cols-3 gap-4 w-full">
				{products.map((product, i) => (
					<ProductCard
						key={product.id}
						product={{
							...product,
							images: product.images.nodes,
							variants: product.variants.nodes
						}}
					/>
				))}
			</div>

			{nextPage && (
				<div className="flex items-center justify-center mt-6">
					{/* <Button
						disabled={fetcher.state !== "idle"}
						variant="secondary"
						onClick={fetchMoreProducts}
						width="full"
						prefetch="intent">
						{fetcher.state !== "idle" ? "Loading..." : "Load more products"}
					</Button> */}
					<Link to={`${url}?index&cursor=${endCursor}`}>Next Page</Link>
				</div>
			)}
		</>
	)
}
