import ReactDOM from "react-dom"
import { useGlobalStore } from "../../shared/useGlobalStore"

import { CSSProperties, Fragment, useEffect, useState } from "react"
import SVGIcon from "../ui/SVGIcon"
import { App } from "../../types"
import CollapsibleItem from "../CollapsibleItem"
import { Link } from "../ui/Link"
import { CMS } from "../../sections/CMS"

type Props = {
	logoSrc: null | CMS.ImageFileField
	shopName: string
	mobileMenu: App.StorefrontAPI.Menu
	activeSegmentedMenu: App.StorefrontAPI.MenuItem
}
const SlideoutMenu = (props: Props) => {
	const showSlideoutMenu = useGlobalStore((state) => state.showSlideoutMenu)
	const zIndex = 1000000

	let selectedMenuCategory: App.StorefrontAPI.MenuItem = useGlobalStore((state) => {
		return state.activeSegmentMenu
	})
	if (selectedMenuCategory === null) {
		selectedMenuCategory = props.activeSegmentedMenu
	}

	useEffect(() => {
		const elem = document.getElementById("SlideoutMenu")
		if (elem == null) {
			if (showSlideoutMenu === true) {
				console.error("could not find #SlideoutMenu")
			}
			return
		}
		requestAnimationFrame(() => {
			elem.style.transform = `translate(${
				showSlideoutMenu === true ? "0%" : "100%"
			}, 0)`
		})
	}, [showSlideoutMenu])

	if (showSlideoutMenu === false || typeof window === "undefined") {
		return null
	}

	type NavMenuItemProps = {
		title: string
		style?: CSSProperties
		url?: string
	}

	const NavMenuItem = (props: NavMenuItemProps) => {
		if (props.url != null) {
			return (
				<Link
					onClick={() => {
						useGlobalStore.setState({
							showSlideoutMenu: false
						})
					}}
					to={props.url}
					key={props.title}
					style={{
						minHeight: "60px",
						borderBottom: "1px solid #e8e8e8",
						fontSize: "20px",
						...props.style
					}}
					className="flex items-center px-[10px]">
					{props.title}
				</Link>
			)
		} else {
			return (
				<div
					key={props.title}
					style={{
						minHeight: "60px",
						borderBottom: "1px solid #e8e8e8",
						fontSize: "20px",
						...props.style
					}}
					className="flex items-center px-[10px]">
					{props.title}
				</div>
			)
		}
	}
	type NavMenuCollapsibleItemProps = {
		items: App.StorefrontAPI.MenuItem[]
		title: string
		style?: CSSProperties
	}
	const NavMenuCollapsibleItem = (props: NavMenuCollapsibleItemProps) => {
		return (
			<CollapsibleItem
				titleWrapperStyle={{
					borderBottom: "1px solid #e8e8e8",
					fontWeight: "normal"
				}}
				id={props.title}
				title={
					<NavMenuItem
						style={{ borderBottom: "unset", minHeight: "40px" }}
						title={props.title}
					/>
				}
				renderBody={() => (
					<div>
						{props.items != null &&
							props.items.map((item) => {
								if (item.items == null) {
									return (
										<NavMenuItem
											style={{
												fontSize: "14px",
												paddingLeft: 40,
												paddingRight: 40
											}}
											title={item.title}
											key={item.title}
										/>
									)
								} else {
									return (
										<NavMenuCollapsibleItem
											style={{
												fontSize: "14px",
												paddingLeft: 40,
												paddingRight: 40
											}}
											key={item.title}
											items={item.items}
											title={item.title}
										/>
									)
								}
							})}
					</div>
				)}
			/>
		)
	}

	return ReactDOM.createPortal(
		<Fragment>
			<div
				onClick={() => useGlobalStore.setState({ showSlideoutMenu: false })}
				style={{
					// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
					display: showSlideoutMenu === true ? "block" : "none",
					position: "fixed",
					zIndex: zIndex - 1,
					left: 0,
					bottom: 0,
					top: 0,
					right: 0,
					height: "100%",
					width: "100vw",
					backgroundColor: "rgba(0,0,0,0.4)"
				}}
			/>
			<div
				id="SlideoutMenu"
				style={{
					position: "fixed",
					zIndex: zIndex,
					top: 0,
					left: 0,
					height: "100%",
					width: "90vw",
					maxWidth: 500,
					overflowY: "auto",
					boxShadow: "1px 1px 15px rgba(0,0,0,0.2)",
					backgroundColor: "#fff",
					willChange: "transform",
					transition: "transform .1s cubic-bezier(.17,.67,.83,.97)",
					transform: `translate(-100%, 0)`
				}}>
				<div
					className="font-bold py-4 flex justify-between items-center px-4"
					style={{
						top: 0,
						zIndex: 10,
						backgroundColor: "#fff"
					}}>
					{props.logoSrc == null ? (
						<h1
							className="text-center"
							style={{ fontWeight: "bold", fontSize: "40px" }}>
							{props.shopName}
						</h1>
					) : (
						<img
							style={{
								maxWidth: 120
							}}
							src={props.logoSrc.reference.image.url}
						/>
					)}
					<div
						style={{
							borderRadius: "100%",
							border: "1px solid #efefef",
							padding: "4"
						}}
						onClick={() => useGlobalStore.setState({ showSlideoutMenu: false })}>
						<SVGIcon name="x" />
					</div>
				</div>
				<div
					className="flex space-x-2 justify-around"
					style={{ borderBottom: "1px solid #e8e8e8" }}>
					{props.mobileMenu.items != null &&
						props.mobileMenu.items.map((item) => {
							const isSelected = selectedMenuCategory.title === item.title
							return (
								<div
									className="py-2 px-4"
									onClick={() => {
										useGlobalStore.setState((prevState) => {
											prevState.activeSegmentMenu = item
											return {
												...prevState
											}
										})
									}}
									key={item.title}
									style={{
										borderBottom: isSelected ? "3px solid #1c1d1d" : "unset",
										fontSize: "12px",
										fontWeight: isSelected ? "bold" : "normal"
									}}>
									{item.title}
								</div>
							)
						})}
				</div>
				<div>
					{selectedMenuCategory.items != null &&
						selectedMenuCategory.items.map((item) => {
							if (item.items?.length === 0) {
								return (
									<NavMenuItem
										title={item.title}
										key={item.title}
										url={item.url != null ? item.url : ""}
									/>
								)
							} else {
								return (
									<NavMenuCollapsibleItem
										key={item.title}
										items={item.items!}
										title={item.title}
									/>
								)
							}
						})}
				</div>
			</div>
		</Fragment>,
		document.body
	)
}

export default SlideoutMenu
