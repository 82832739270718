import SwiperWrapper from "../Swiper"

const announcments = ["Frakt 49,- | Fri frakt over 1.000,-"]
const AnnouncmentBar = () => {
	return (
		<div
			style={{
				padding: "12px 0px",
				color: "white",
				background: "linear-gradient(90deg, #edb88b 0%, var(--color-cta) 100%)"
			}}>
			<div className="container">
				<SwiperWrapper>
					{announcments.map((announcment, i) => {
						return (
							<div
								key={i}
								className="text-xs"
								style={{
									textAlign: "center",
									letterSpacing: 0
								}}>
								{announcment}
							</div>
						)
					})}
				</SwiperWrapper>
			</div>
		</div>
	)
}
export default AnnouncmentBar
