import { CSSProperties } from "react"
import { locales } from "../../shared/locales"
import { useGlobalStore } from "../../shared/useGlobalStore"
import { LocationCartStatusIndicator } from "../location-picker/LocationPickerModal"

export function InventoryStatus(props: {
	inProgress: boolean
	shippable: boolean
	pickUp: boolean
	className?: string
	style?: CSSProperties
}) {
	const selectedLocation = useGlobalStore((state) => state.selectedLocation)

	const boxStyle: CSSProperties = {
		borderRadius: "var(--button-border-radius)",
		flexGrow: "unset",
		flexShrink: 0,
		width: "50%",
		backgroundColor: "#efefef50",
		border: "1px solid rgba(0,0,0,0.4)",
		height: 46
	}

	const boxClassName =
		"p-2 flex items-center justify-between space-x-2 font-bold text-sm"

	return (
		<div
			style={{
				borderRadius: 6,
				...props.style,
				boxShadow: "2px 2px 10px rgba(0,0,0,0.12)"
			}}
			className={props.className}>
			<div className="flex items-center">
				<div
					style={{
						...boxStyle,
						width: "40%",
						borderTopRightRadius: 0,
						borderBottomRightRadius: 0
					}}
					className={boxClassName}>
					<div className="flex space-x-2 items-center">
						<img
							style={{ width: 22 }}
							src="https://img.icons8.com/windows/512/in-transit.png"
						/>
						<a className="max-md:text-[12px]">{locales.custom.shipping}</a>
					</div>
					<div className="flex items-center">
						<LocationCartStatusIndicator
							showStatusMessage={false}
							status={{
								available: 1,
								status:
									props.shippable === true ? "all-available" : "unavailable",
								unavailable: 0
							}}
						/>
					</div>
				</div>

				<div
					style={{
						...boxStyle,
						width: "60%",
						borderLeftWidth: 0,
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0
					}}
					onClick={() => {
						useGlobalStore.setState({
							locationPickerModal: {
								show: true,
								withCartInfo: true
							}
						})
					}}
					className={`${boxClassName} hover:opacity-[0.5] cursor-pointer`}>
					<div
						style={{
							width: "100%",
							maxWidth: "calc(100% - 26px)",
							letterSpacing: "0px"
						}}
						className="flex space-x-2 items-center flex-shrink-0">
						<div style={{ textAlign: "center", lineHeight: "10px" }}>
							<img
								className="mx-auto"
								style={{ width: 20 }}
								src="https://img.icons8.com/external-sbts2018-outline-sbts2018/512/external-store-black-friday-5-basic-sbts2018-outline-sbts2018.png"
							/>
						</div>
						{selectedLocation != null ? (
							<a
								style={{
									width: "100%",
									display: "inline-block",
									textAlign: "center",
									lineHeight: "13px",
									fontSize: 11
								}}>
								{selectedLocation.name}
								<br />
								<span className="text-[11px] underline">
									{locales.custom.change_store}
								</span>
							</a>
						) : (
							<a
								style={{
									width: "100%",
									display: "inline-block",
									textAlign: "center",
									lineHeight: "12px",
									textDecoration: "underline",
									fontSize: 11
								}}>
								{locales.custom.select_location}
							</a>
						)}
					</div>
					{selectedLocation != null && (
						<LocationCartStatusIndicator
							showStatusMessage={false}
							status={{
								available: 1,
								status: props.pickUp === true ? "all-available" : "unavailable",
								unavailable: 0
							}}
						/>
					)}
				</div>
			</div>
		</div>
	)
}
