// make sure to import React to avoid issues with preact compat
import { style } from "motion"
import { CSSProperties, Fragment } from "react"

export type PaymentsSVGIconProps = {
	style?: CSSProperties
	name: string
}

const PaymentsSVGIcon = (props: PaymentsSVGIconProps): null | JSX.Element => {
	if (props.name === "american_express") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				role="img"
				viewBox="0 0 38 24"
				width="38"
				height="24"
				style={{
					...props.style
				}}
				aria-labelledby="pi-american_express">
				<title id="pi-american_express">American Express</title>
				<g fill="none">
					<path
						fill="#000"
						d="M35,0 L3,0 C1.3,0 0,1.3 0,3 L0,21 C0,22.7 1.4,24 3,24 L35,24 C36.7,24 38,22.7 38,21 L38,3 C38,1.3 36.6,0 35,0 Z"
						opacity=".07"></path>
					<path
						fill="#006FCF"
						d="M35,1 C36.1,1 37,1.9 37,3 L37,21 C37,22.1 36.1,23 35,23 L3,23 C1.9,23 1,22.1 1,21 L1,3 C1,1.9 1.9,1 3,1 L35,1"></path>
					<path
						fill="#FFF"
						d="M8.971,10.268 L9.745,12.144 L8.203,12.144 L8.971,10.268 Z M25.046,10.346 L22.069,10.346 L22.069,11.173 L24.998,11.173 L24.998,12.412 L22.075,12.412 L22.075,13.334 L25.052,13.334 L25.052,14.073 L27.129,11.828 L25.052,9.488 L25.046,10.346 L25.046,10.346 Z M10.983,8.006 L14.978,8.006 L15.865,9.941 L16.687,8 L27.057,8 L28.135,9.19 L29.25,8 L34.013,8 L30.494,11.852 L33.977,15.68 L29.143,15.68 L28.065,14.49 L26.94,15.68 L10.03,15.68 L9.536,14.49 L8.406,14.49 L7.911,15.68 L4,15.68 L7.286,8 L10.716,8 L10.983,8.006 Z M19.646,9.084 L17.407,9.084 L15.907,12.62 L14.282,9.084 L12.06,9.084 L12.06,13.894 L10,9.084 L8.007,9.084 L5.625,14.596 L7.18,14.596 L7.674,13.406 L10.27,13.406 L10.764,14.596 L13.484,14.596 L13.484,10.661 L15.235,14.602 L16.425,14.602 L18.165,10.673 L18.165,14.603 L19.623,14.603 L19.647,9.083 L19.646,9.084 Z M28.986,11.852 L31.517,9.084 L29.695,9.084 L28.094,10.81 L26.546,9.084 L20.652,9.084 L20.652,14.602 L26.462,14.602 L28.076,12.864 L29.624,14.602 L31.499,14.602 L28.987,11.852 L28.986,11.852 Z"></path>
				</g>
			</svg>
		)
	} else if (props.name === "mastercard") {
		return (
			<svg
				viewBox="0 0 38 24"
				xmlns="http://www.w3.org/2000/svg"
				role="img"
				width="38"
				height="24"
				style={{
					...props.style
				}}
				aria-labelledby="pi-master">
				<title id="pi-master">Mastercard</title>
				<path
					opacity=".07"
					d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path>
				<path
					fill="#fff"
					d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path>
				<circle
					fill="#EB001B"
					cx="15"
					cy="12"
					r="7"></circle>
				<circle
					fill="#F79E1B"
					cx="23"
					cy="12"
					r="7"></circle>
				<path
					fill="#FF5F00"
					d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"></path>
			</svg>
		)
	} else if (props.name === "visa") {
		return (
			<svg
				viewBox="0 0 38 24"
				xmlns="http://www.w3.org/2000/svg"
				role="img"
				width="38"
				height="24"
				style={{
					...props.style
				}}
				aria-labelledby="pi-visa">
				<title id="pi-visa">Visa</title>
				<path
					opacity=".07"
					d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path>
				<path
					fill="#fff"
					d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path>
				<path
					d="M28.3 10.1H28c-.4 1-.7 1.5-1 3h1.9c-.3-1.5-.3-2.2-.6-3zm2.9 5.9h-1.7c-.1 0-.1 0-.2-.1l-.2-.9-.1-.2h-2.4c-.1 0-.2 0-.2.2l-.3.9c0 .1-.1.1-.1.1h-2.1l.2-.5L27 8.7c0-.5.3-.7.8-.7h1.5c.1 0 .2 0 .2.2l1.4 6.5c.1.4.2.7.2 1.1.1.1.1.1.1.2zm-13.4-.3l.4-1.8c.1 0 .2.1.2.1.7.3 1.4.5 2.1.4.2 0 .5-.1.7-.2.5-.2.5-.7.1-1.1-.2-.2-.5-.3-.8-.5-.4-.2-.8-.4-1.1-.7-1.2-1-.8-2.4-.1-3.1.6-.4.9-.8 1.7-.8 1.2 0 2.5 0 3.1.2h.1c-.1.6-.2 1.1-.4 1.7-.5-.2-1-.4-1.5-.4-.3 0-.6 0-.9.1-.2 0-.3.1-.4.2-.2.2-.2.5 0 .7l.5.4c.4.2.8.4 1.1.6.5.3 1 .8 1.1 1.4.2.9-.1 1.7-.9 2.3-.5.4-.7.6-1.4.6-1.4 0-2.5.1-3.4-.2-.1.2-.1.2-.2.1zm-3.5.3c.1-.7.1-.7.2-1 .5-2.2 1-4.5 1.4-6.7.1-.2.1-.3.3-.3H18c-.2 1.2-.4 2.1-.7 3.2-.3 1.5-.6 3-1 4.5 0 .2-.1.2-.3.2M5 8.2c0-.1.2-.2.3-.2h3.4c.5 0 .9.3 1 .8l.9 4.4c0 .1 0 .1.1.2 0-.1.1-.1.1-.1l2.1-5.1c-.1-.1 0-.2.1-.2h2.1c0 .1 0 .1-.1.2l-3.1 7.3c-.1.2-.1.3-.2.4-.1.1-.3 0-.5 0H9.7c-.1 0-.2 0-.2-.2L7.9 9.5c-.2-.2-.5-.5-.9-.6-.6-.3-1.7-.5-1.9-.5L5 8.2z"
					fill="#142688"></path>
			</svg>
		)
	} else if (props.name === "klarna") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				role="img"
				width="38"
				height="24"
				viewBox="0 0 38 24"
				aria-labelledby="pi-klarna">
				<title id="pi-klarna">Klarna</title>
				<g
					stroke="none"
					strokeWidth="1"
					fill="none"
					fillRule="evenodd">
					<path
						d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
						fill="#FFB3C7"></path>
					<path
						d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
						fill="#FFB3C7"></path>
					<path
						d="M34.117 13.184c-.487 0-.882.4-.882.892 0 .493.395.893.882.893.488 0 .883-.4.883-.893a.888.888 0 00-.883-.892zm-2.903-.69c0-.676-.57-1.223-1.274-1.223-.704 0-1.274.547-1.274 1.222 0 .675.57 1.223 1.274 1.223.704 0 1.274-.548 1.274-1.223zm.005-2.376h1.406v4.75h-1.406v-.303a2.446 2.446 0 01-1.394.435c-1.369 0-2.478-1.122-2.478-2.507 0-1.384 1.11-2.506 2.478-2.506.517 0 .996.16 1.394.435v-.304zm-11.253.619v-.619h-1.44v4.75h1.443v-2.217c0-.749.802-1.15 1.359-1.15h.016v-1.382c-.57 0-1.096.247-1.378.618zm-3.586 1.756c0-.675-.57-1.222-1.274-1.222-.703 0-1.274.547-1.274 1.222 0 .675.57 1.223 1.274 1.223.704 0 1.274-.548 1.274-1.223zm.005-2.375h1.406v4.75h-1.406v-.303A2.446 2.446 0 0114.99 15c-1.368 0-2.478-1.122-2.478-2.507 0-1.384 1.11-2.506 2.478-2.506.517 0 .997.16 1.394.435v-.304zm8.463-.128c-.561 0-1.093.177-1.448.663v-.535H22v4.75h1.417v-2.496c0-.722.479-1.076 1.055-1.076.618 0 .973.374.973 1.066v2.507h1.405v-3.021c0-1.106-.87-1.858-2.002-1.858zM10.465 14.87h1.472V8h-1.472v6.868zM4 14.87h1.558V8H4v6.87zM9.45 8a5.497 5.497 0 01-1.593 3.9l2.154 2.97H8.086l-2.341-3.228.604-.458A3.96 3.96 0 007.926 8H9.45z"
						fill="#0A0B09"
						fillRule="nonzero"></path>
				</g>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					role="img"
					width="38"
					height="24"
					viewBox="0 0 38 24"
					style={{
						...props.style
					}}
					aria-labelledby="pi-klarna">
					<title id="pi-klarna">Klarna</title>
					<g
						stroke="none"
						strokeWidth="1"
						fill="none"
						fillRule="evenodd">
						<path
							d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
							fill="#FFB3C7"></path>
						<path
							d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
							fill="#FFB3C7"></path>
						<path
							d="M34.117 13.184c-.487 0-.882.4-.882.892 0 .493.395.893.882.893.488 0 .883-.4.883-.893a.888.888 0 00-.883-.892zm-2.903-.69c0-.676-.57-1.223-1.274-1.223-.704 0-1.274.547-1.274 1.222 0 .675.57 1.223 1.274 1.223.704 0 1.274-.548 1.274-1.223zm.005-2.376h1.406v4.75h-1.406v-.303a2.446 2.446 0 01-1.394.435c-1.369 0-2.478-1.122-2.478-2.507 0-1.384 1.11-2.506 2.478-2.506.517 0 .996.16 1.394.435v-.304zm-11.253.619v-.619h-1.44v4.75h1.443v-2.217c0-.749.802-1.15 1.359-1.15h.016v-1.382c-.57 0-1.096.247-1.378.618zm-3.586 1.756c0-.675-.57-1.222-1.274-1.222-.703 0-1.274.547-1.274 1.222 0 .675.57 1.223 1.274 1.223.704 0 1.274-.548 1.274-1.223zm.005-2.375h1.406v4.75h-1.406v-.303A2.446 2.446 0 0114.99 15c-1.368 0-2.478-1.122-2.478-2.507 0-1.384 1.11-2.506 2.478-2.506.517 0 .997.16 1.394.435v-.304zm8.463-.128c-.561 0-1.093.177-1.448.663v-.535H22v4.75h1.417v-2.496c0-.722.479-1.076 1.055-1.076.618 0 .973.374.973 1.066v2.507h1.405v-3.021c0-1.106-.87-1.858-2.002-1.858zM10.465 14.87h1.472V8h-1.472v6.868zM4 14.87h1.558V8H4v6.87zM9.45 8a5.497 5.497 0 01-1.593 3.9l2.154 2.97H8.086l-2.341-3.228.604-.458A3.96 3.96 0 007.926 8H9.45z"
							fill="#0A0B09"
							fillRule="nonzero"></path>
					</g>
				</svg>
			</svg>
		)
	} else if (props.name === "vipps") {
		return (
			<svg
				viewBox="0 0 38 24"
				xmlns="http://www.w3.org/2000/svg"
				role="img"
				width="38"
				height="24"
				style={{
					...props.style
				}}
				aria-labelledby="pi-vipps">
				<title id="pi-vipps">Vipps</title>
				<path
					opacity=".07"
					d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path>
				<path
					fill="#fff"
					d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					fill="#FF5B24"
					d="M27.414 12.07c.357.281.545.667.564 1.155 0 .487-.188.977-.564 1.464C25.218 17.563 22.551 19 19.415 19a9.454 9.454 0 01-5.042-1.437c-1.521-.939-2.836-2.273-3.944-4.001-.319-.469-.451-.929-.394-1.38.038-.432.244-.779.62-1.042.356-.244.76-.31 1.211-.197.469.132.864.432 1.183.901.827 1.239 1.747 2.197 2.761 2.873 1.127.732 2.329 1.099 3.606 1.099 1.22 0 2.29-.291 3.211-.873.751-.47 1.521-1.221 2.31-2.254.395-.47.816-.761 1.268-.873s.852-.028 1.209.254zm-3.746-6.451c.432.413.648.911.648 1.493 0 .639-.217 1.155-.648 1.55-.413.375-.91.563-1.492.563s-1.08-.188-1.494-.563c-.412-.395-.619-.911-.619-1.55 0-.62.207-1.127.619-1.521.414-.394.912-.591 1.494-.591s1.079.206 1.492.619z"></path>
			</svg>
		)
	} else {
		return null
	}
}

export default PaymentsSVGIcon
