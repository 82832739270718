import { ProductCard, Section } from "~/components"
import { App } from "../types"

export function ProductSwimlane({
	title = "Featured Products",
	products,
	count = 12,
	...props
}: {
	title?: string
	count?: number
	products: App.StorefrontAPI.Product[]
}) {
	return (
		<Section
			heading={title}
			padding="y"
			{...props}>
			<div className="swimlane hiddenScroll md:pb-8 md:scroll-px-8 lg:scroll-px-12 md:px-8 lg:px-12">
				{products.map((product) => (
					<ProductCard
						product={product}
						key={product.id}
					/>
				))}
			</div>
		</Section>
	)
}
